<template>
    <div>
      <!-- modal form input and edit -->
      <b-modal
        id="modal-form-ormas"
        ok-only
        centered
        size="xl"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container fluid class="pl-0 pr-0">
            <b-row class="pt-5 mt-2 mb-4">
              <b-col class="d-flex justify-content-between pr-0">
                <b style="font-size:20px;">
                  {{activeTab == 0 ?'Profil Ormas' : ''}}
                  {{activeTab == 1 ?'Info Pendirian Ormas' : ''}}
                  {{activeTab == 2 ?'Dokumen Ormas' : ''}}
                  {{activeTab == 3 ?'Struktur Organisasi' : ''}}
                  {{activeTab == 4 ?'Rekening Ormas' : ''}}
                  {{activeTab == 5 ?'Cabang Ormas' : ''}}
                </b>
                <div>
                  <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4" @click="$bvModal.hide('modal-form-ormas')">
                    <v-icon class="pr-3">mdi-close</v-icon>
                    Tutup
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-tabs pills card justified v-model="activeTab" style="border-radius:10px;">
          
              <b-tab active title="Profil" style="background-color:#FAFAFA;margin-top:0px;">
                <b-row>
                  <input style="display: none;" @change="uploadFile2" type="file" ref="fileInput2"/>
                  <b-col cols="12">
                    <b-row class="pt-3 pb-3 d-flex align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Unggah Logo Organisasi" }}</p></b-col
                      >:<b-col sm="2">
                        <b-button v-if="!file_logo || file_logo == ''" variant="primary" size="sm" @click="fileClicked2('logo')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                        <a v-if="file_logo && file_logo != ''" :href="fileURL + file_logo" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                        </a>
                        <b-button v-if="file_logo && file_logo != ''" @click="file_logo = ''" variant="danger" size="sm" class="ml-3">
                          <v-icon class="p-0">mdi-delete-outline</v-icon>
                        </b-button>
                      </b-col>
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Unggah Bendera Organisasi" }}</p></b-col
                      >:<b-col sm="3">
                        <b-button v-if="!file_bendera || file_bendera == ''" variant="primary" size="sm" @click="fileClicked2('bendera')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                        <a v-if="file_bendera && file_bendera != ''" :href="fileURL + file_bendera" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                        </a>
                        <b-button v-if="file_bendera && file_bendera != ''" @click="file_bendera = ''" variant="danger" size="sm" class="ml-3">
                          <v-icon class="p-0">mdi-delete-outline</v-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;" class="required">{{ "Nama Ormas" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-input v-model="$v.formProfil.nama_ormas.$model" :state="validateState('nama_ormas')" placeholder="Silahkan masukkan nama ormas"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Singkatan" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-input  placeholder="Silahkan masukkan singkatan" v-model="$v.formProfil.singkatan.$model"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">
                          {{ "Status Sekretariat" }} 
                        </p></b-col
                      >:<b-col sm="6">
                        
                        <b-form-select v-model="$v.formProfil.status_sekretariat.$model" :state="validateState('status_sekretariat')">
                          <!-- <b-form-select-option value="" disabled>Silahkan masukkan status sekretariat</b-form-select-option> -->
                          <b-form-select-option :value="item.id" v-for="(item,index) in listSekretariat" :key="index">{{item.namaLokasi}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">
                          {{ "Alamat sekretariat" }} 
                        </p></b-col
                      >:<b-col sm="6">
                        <b-form-textarea
                          id="textarea"
                          placeholder="Silahkan masukkan alamat sekretariat"
                          v-model="$v.formProfil.alamat_sekretariat.$model" :state="validateState('alamat_sekretariat')"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;" class="required">{{ "Propinsi" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-select :disabled="disProp" v-model="$v.selectedPropinsi.$model" :state="validateStateN('selectedPropinsi')">
                          <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                          <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;" class="required">{{ "Kabupaten/Kota" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-select :disabled="disKab" v-model="$v.formProfil.kabupaten_kota_sekretariat.$model" :state="validateStateKot('kabupaten_kota_sekretariat')">
                          <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten/kota</b-form-select-option>
                          <b-form-select-option v-for="(item,index) in listKotaProfil" :key="index" :value="item.kode">{{item.nama}}</b-form-select-option>
                          
                        </b-form-select>
                        
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Nomor Telepon" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-input v-model="$v.formProfil.no_telp.$model" :state="validateState('no_telp')" placeholder="Silahkan masukkan nomor telepon" description="Hanya 1 nomor telepon"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Nomor FAX" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-input v-model="$v.formProfil.no_fax.$model" :state="validateState('no_fax')" placeholder="Silahkan masukkan nomor fax" description="Hanya 1 nomor fax"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Email" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-input v-model="$v.formProfil.email.$model" :state="validateState('email')" placeholder="Silahkan masukkan email"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">
                          {{ "Tujuan organisasi" }} 
                        </p></b-col
                      >:
                      <b-col sm="6">
                          <b-form-input v-model="$v.formProfil.tujuan_ormas.$model" :state="validateState('tujuan_ormas')" placeholder="Silahkan masukkan tujuan organisasi"></b-form-input>
                      </b-col>
                    </b-row>
                    
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-input v-model="$v.formProfil.npwp.$model" :state="validateState('npwp')" placeholder="Silahkan masukkan NPWP"></b-form-input>
                      </b-col>
                      <b-col sm="2">
                        <b-button v-if="file_npwp == '' || file_npwp == null " variant="primary" size="sm" @click="fileClicked2('npwp')"><v-icon>mdi-upload</v-icon> Unggah</b-button>
                        <a v-if="file_npwp != '' && file_npwp !== null" :href="fileURL + file_npwp" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                        </a>
                        <b-button v-if="file_npwp != '' && file_npwp !== null" @click="file_npwp = ''" variant="danger" size="sm" class="ml-3">
                          <v-icon class="p-0">mdi-delete-outline</v-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Ciri khusus" }} </p></b-col
                      >:<b-col sm="6">
                        <b-form-select v-model="$v.formProfil.m_ciri_khusus_id.$model" :state="validateState('m_ciri_khusus_id')">
                          <b-form-select-option value="" disabled>Silahkan masukkan Ciri Khusus</b-form-select-option>
                          <b-form-select-option :value="item.id" v-for="(item,index) in listCiriKhusus" :key="index">{{item.namaCiriKhusus}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row :class="formProfil.bidang_kegiatan.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Bidang Kegiatan" }} </p></b-col
                      >:<b-col sm="8">
                        <b-row v-for="(i,index) in formProfil.bidang_kegiatan" :key="index" class="pb-2">
                          <b-col>
                            <!-- <div class="d-flex align-items-center"> -->
                              <b-row>
                                <b-col cols="9">
                                  <b-form-select v-model="$v.formProfil.bidang_kegiatan.$each.$iter[index].id.$model" :state="validateStateFPBid(index)">
                                    <b-form-select-option value="" disabled>Silahkan masukkan Bidang Kegiatan</b-form-select-option>
                                    <b-form-select-option :value="item.id" v-for="(item,index) in listBidangKegiatan" :key="index">{{item.namaJenisOrmas}}</b-form-select-option>
                                  </b-form-select>
                                </b-col>
                                <b-col cols="3" class="d-flex align-items-center pl-0">
                                  <div class="button-minus" v-if="index != 0 && formProfil.bidang_kegiatan.length - index == 1"><v-icon style="color:white;" small @click="formProfil.bidang_kegiatan.splice(-1)">mdi-minus</v-icon></div>
                                  <div class="button-plus" v-if="formProfil.bidang_kegiatan.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                                  <div class="button-plus" v-if="formProfil.bidang_kegiatan.length - index == 1"><v-icon style="color:white;" small @click="formProfil.bidang_kegiatan.push({id: ''})">mdi-plus</v-icon></div>
                                </b-col>
                              </b-row>
                            <!-- </div> -->
                          </b-col>
                        
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Wadah Berhimpun" }} </p></b-col
                      >:<b-col sm="8">
                              <b-row>
                                <b-col cols="9" >
                                  <b-form-select v-model="$v.formProfil.wadahBerhimpun.$model" :state="validateState('wadahBerhimpun')">
                                    <b-form-select-option value="" disabled>Silahkan masukkan Wadah Berhimpun</b-form-select-option>
                                    <b-form-select-option :value="item.id" v-for="(item,index) in listBadanOtonom" :key="index">{{item.nama_ormas}}</b-form-select-option>
                                  </b-form-select>
                                </b-col>
                              </b-row>
                      </b-col>
                    </b-row>
                    <b-row :class="formProfil.badan_otonom.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">{{ "Badan Otonom / Sayap Organisasi" }} </p></b-col
                      >:<b-col sm="8">
                        <b-row v-for="(i,index) in formProfil.badan_otonom" :key="index" class="pb-2">
                          <b-col>
                              <b-row>
                                <b-col cols="9" >
                                  <b-form-select v-model="$v.formProfil.badan_otonom.$each.$iter[index].id.$model" :state="validateStateFPBid2(index)">
                                    <b-form-select-option value="" disabled>Silahkan masukkan Badan Otonom / Sayap Organisasi</b-form-select-option>
                                    <b-form-select-option :value="item.id" v-for="(item,index) in listBadanOtonom" :key="index">{{item.nama_ormas}}</b-form-select-option>
                                  </b-form-select>
                                </b-col>
                                <b-col cols="3" class="d-flex align-items-center pl-0">
                                  <div class="button-minus" v-if="index != 0 && formProfil.badan_otonom.length - index == 1"><v-icon style="color:white;" small @click="formProfil.badan_otonom.splice(-1)">mdi-minus</v-icon></div>
                                  <div class="button-plus" v-if="formProfil.badan_otonom.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                                  <div class="button-plus" v-if="formProfil.badan_otonom.length - index == 1"><v-icon style="color:white;" small @click="formProfil.badan_otonom.push({id:''})">mdi-plus</v-icon></div>
                                </b-col>
                              </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col class="d-flex justify-content-center pt-5 mt-5">
                    <b-button variant="primary" size="lg" style="width:200px;" @click="cekProfile()">
                      Lanjut
                    </b-button>
                  </b-col>
                </b-row>

              </b-tab>

              <b-tab title="Info Pendirian" style="background-color:#FAFAFA;margin-top:0px;"
                ><b-row>
                  <b-col cols="12">
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Nama Notaris </p></b-col
                      >:<b-col sm="4">
                        <b-form-input v-model="$v.formPendiri.namaNotaris.$model" :state="validateState2('namaNotaris')" placeholder="Silahkan masukkan nama notaris"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian </p></b-col
                      >:<b-col sm="4">
                        <b-form-input v-model="$v.formPendiri.nomorAkta.$model" :state="validateState2('nomorAkta')" placeholder="Silahkan masukkan nomor akta pendirian"></b-form-input>
                      </b-col>
                      <b-col sm="2">
                        <b-button v-if="!formPendiri.file_akta || formPendiri.file_akta == '' || formPendiri.file_akta == null" variant="primary" size="sm" @click="fileClicked2('scanNotaris')"><v-icon>mdi-file-plus</v-icon> Unggah file</b-button>
                        <a v-if="formPendiri.file_akta && formPendiri.file_akta !== '' && formPendiri.file_akta !== null" :href="fileURL + formPendiri.file_akta" target="_blank" type="button">
                        <b-button variant="success" size="sm">
                          Lihat File
                        </b-button>
                        </a>
                        <b-button v-if="formPendiri.file_akta != '' && formPendiri.file_akta != null" @click="formPendiri.file_akta = ''" variant="danger" size="sm" class="ml-3">
                          <v-icon class="p-0">mdi-delete-outline</v-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Tanggal AKTA Pendirian </p></b-col
                      >:<b-col sm="4">
                        <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="$v.formPendiri.tanggalAkta.$model" :state="validateState2('tanggalAkta')" id="tanggalAkta" class="mb-2"></b-form-datepicker>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Tempat Pendirian </p></b-col
                      >:<b-col sm="4">
                        <b-form-select :disabled="disProp" v-model="$v.selectedPendiriPropinsi.$model" :state="validateStateP('selectedPendiriPropinsi')">
                          <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                          <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col sm="4">
                        <b-form-select :disabled="disKab" v-model="$v.formPendiri.kabupatenKota.$model" :state="validateStateKot2('kabupatenKota')">
                          <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                            <b-form-select-option v-for="(item,index) in listKota2" :key="index" :value="item.kode">{{item.nama}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Tanggal Pendirian </p></b-col
                      >:<b-col sm="4">
                        <b-form-datepicker :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="$v.formPendiri.tanggalPendirian.$model" :state="validateState2('tanggalPendirian')" id="tanggalPendirian"  class="mb-2"></b-form-datepicker>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Nomor Surat Pendaftaran </p></b-col
                      >:<b-col sm="4">
                        <b-form-input v-model="$v.formPendiri.nomorSuratPendaftaran.$model" :state="validateState2('nomorSuratPendaftaran')" placeholder="Silahkan masukkan nomor surat pendaftaran"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Tanggal Surat Pendaftaran </p></b-col
                      >:<b-col sm="4">
                        <b-form-datepicker
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" id="tanggalSuratPendaftaran" 
                        v-model="$v.formPendiri.tanggalSuratPendaftaran.$model" :state="validateState2('tanggalSuratPendaftaran')" class="mb-2"></b-form-datepicker>
                      </b-col>
                    </b-row>
                    <!-- <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Nama Kespangpol </p></b-col
                      >:<b-col sm="4">
                        <b-form-input v-model="$v.formPendiri.nomorSuratPendaftaran.$model" :state="validateState2('nomorSuratPendaftaran')" placeholder="Silahkan masukkan nomor surat pendaftaran"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Email Kespangpol </p></b-col
                      >:<b-col sm="4">
                        <b-form-input v-model="$v.formPendiri.nomorSuratPendaftaran.$model" :state="validateState2('nomorSuratPendaftaran')" placeholder="Silahkan masukkan nomor surat pendaftaran"></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="pt-3 pb-3 align-items-center">
                      <b-col sm="3"
                        ><p style="font-weight: 600; margin:0px;">Nomor HP Kesbangpol </p></b-col
                      >:<b-col sm="4">
                        <b-form-input v-model="$v.formPendiri.nomorSuratPendaftaran.$model" :state="validateState2('nomorSuratPendaftaran')" placeholder="Silahkan masukkan nomor surat pendaftaran"></b-form-input>
                      </b-col>
                    </b-row> -->
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <b-col class="d-flex justify-content-center pt-5 mt-5">
                    <button
                      class="mt-16 text-center button-back mr-5"
                      @click="back()"
                    >
                      Kembali
                    </button>
                    <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekInfoPendirian()">
                      Lanjut
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Dokumen Ormas" style="background-color:#FAFAFA;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  ">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px">No</th>
                        <th style="min-width: 50px" class="text-capitalize">Deskripsi</th>
                        <th style="width: 250px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, index) in listDokumen">
                        <tr v-bind:key="index">
                          <td>
                            <span
                              class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                              >{{ index + 1 }}</span
                            >
                          </td>
                          <td class="text-left">
                            {{ item.namaJenisDokumen}}
                          </td>
                          <td class="d-flex justify-content-start">
                            
                            <b-button :disabled="loadUpload" size="sm" class="mr-3" variant="primary" @click="fileClicked(item)">
                              <v-icon v-if="!loadUpload" style="color:white;" small>mdi-file-plus</v-icon>
                              
                              {{loadUpload ? 'Sedang Upload' : 'Unggah File'}}
                            </b-button>
                            <b-button v-if="cekUploaded(item.id)" variant="success" size="sm" @click="seeUploaded(item.id)">
                              <v-icon>
                                mdi-clipboard
                              </v-icon>
                              &nbsp;
                              Lihat File
                            </b-button>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                   <input style="display: none;" @change="uploadFile" type="file" :accept="rules" ref="fileInput"/>
                </div>
                <b-row class="mt-5">
                  <b-col class="d-flex justify-content-center pt-5 mt-5">
                    <button
                      class="mt-16 text-center button-back mr-5"
                      @click="back()"
                    >
                      Kembali
                    </button>
                    <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekDokumen()">
                      Lanjut
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Struktur Organisasi" style="background-color:#FAFAFA;margin-top:0px;">
                <b-card-text class="pl-0 pr-0">
                  <div class="card-body pt-0 pb-3 pl-0 pr-0">
                    <div class="tab-content">
                      <!--begin::Table-->
                      <div style="max-width: 1276px;overflow-x: scroll;">
                    <div class="table-responsive" style="width:1600px !important;">
                      <table
                        class="
                          table
                          table-head-custom
                          table-vertical-center
                          table-head-bg
                          table-bordered
                        "
                      >
                          <thead>
                            <tr class="text-left">
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                              <!-- <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tempat</th> -->
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                              <th style="padding-left:0px; padding-right: 0px;" class="text-capitalize">Aksi</th>
                            </tr>
                          </thead>
                          <tbody style="background:#FAFAFA;">
                            <template v-for="(item, i) in $v.formStruktur.pengurusInti.$each.$iter">
                              <tr v-bind:key="i">
                                <td class="d-flex justify-content-center">
                                  <div  v-if="formStruktur.pengurusInti[i].foto == ''" @click="fileClicked2('peng_foto' + i)" style="cursor:pointer;background: #C4C4C4;width: 56px;height: 56px;border-radius: 10px;display:flex; align-items:center;justify-content:center;">
                                  <v-icon>mdi-camera</v-icon>
                                </div>
                                <img v-if="formStruktur.pengurusInti[i].foto !== ''"
                                 @click="fileClicked('peng_foto' + i)"
                                  :src="fileURL + formStruktur.pengurusInti[i].foto"
                                  style="cursor:pointer;width: 56px;height: 56px;"
                                  alt="abstract"
                                  @error="replaceByDefault"
                                />
                                </td>
                                <td>
                                  <b-form-input  v-model="item.nama_pengurus.$model" :state="validateStateSOid(i, 'nama_pengurus')" :placeholder="'Nama '+item.namaJabatan"></b-form-input>
                                </td>
                                <td>
                                  <div class="d-flex justify-content-between align-items-center">
                                  <b-form-datepicker :id="'mulai'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                  v-model="item.periode_mulai.$model" :state="validateStateSOid(i, 'periode_mulai')" placeholder="Mulai" 
                                  style="height:40px;width:130px;"></b-form-datepicker> - 
                                  <b-form-datepicker :id="'akhir'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                  v-model="item.periode_selesai.$model" :state="validateStateSOid(i, 'periode_selesai')" placeholder="Selesai" 
                                  style="height:40px;width:130px;"></b-form-datepicker>
                                  </div>
                                </td>
                                <td>
                                  <b-form-select v-model="item.m_jabatan_id.$model" :state="validateStateSOid(i, 'm_jabatan_id')">
                                    <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                                    <b-form-select-option :value="item.id" v-for="(item,index) in listJabatan" :key="index">{{item.namaJabatan}}</b-form-select-option>
                                  </b-form-select>
                                </td>
                                <td>
                                  <b-form-input :disabled="formStruktur.pengurusInti[i].idDis" v-model="item.identitas.$model" :state="validateStateSOid(i, 'identitas')" placeholder="No Identitas"></b-form-input>
                                </td>
                                <td>
                                  <b-form-datepicker :id="'er'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                  v-model="item.tanggal_lahir.$model" :state="validateStateSOid(i, 'tanggal_lahir')" placeholder="TTL" 
                                  style="height:40px;width:130px;" class="mb-2"></b-form-datepicker>
                                </td>
                                <td>
                                  <b-form-input placeholder="No HP" v-model="item.no_hp.$model" :state="validateStateSOid(i, 'no_hp')"></b-form-input>
                                </td>
                                <td>
                                <b-button v-if="formStruktur.pengurusInti[i].file_identitas == ''" size="sm" variant="primary" @click="fileClicked2('peng_ktp' + i)">
                                  <v-icon class="mr-1">
                                    mdi-credit-card
                                  </v-icon>
                                  Unggah KTP
                                </b-button>
                                <a v-if="formStruktur.pengurusInti[i].file_identitas != ''" :href="fileURL + formStruktur.pengurusInti[i].file_identitas" target="_blank" type="button">
                                <b-button variant="success" size="sm">
                                  Lihat File
                                </b-button>
                                </a>
                                <b-button v-if="formStruktur.pengurusInti[i].file_identitas != ''" @click="formStruktur.pengurusInti[i].file_identitas = ''" variant="danger" size="sm" class="ml-3">
                                  <v-icon class="p-0">mdi-delete-outline</v-icon>
                                </b-button>
                              </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      </div>
                        
                      <!--end::Table-->
                      <div class="mt-5">

                        <b-row :class="formStruktur.pengurus.pendiri && formStruktur.pengurus.pendiri.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                          <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Daftar Pendiri Organisasi </p></b-col
                          >:<b-col sm="8">
                            <b-row v-for="(i,index) in formStruktur.pengurus.pendiri" :key="index" class="pb-2">
                              <b-col cols="10" class="pr-0 d-flex">
                                <b-form-input v-model="$v.formStruktur.pengurus.pendiri.$each.$iter[index].nama_pengurus.$model" :state="validateStateSOP(index, 'nama_pengurus')" placeholder="Silahkan masukkan daftar pendiri organisasi"></b-form-input>
                                <b-form-input v-model="$v.formStruktur.pengurus.pendiri.$each.$iter[index].identitas.$model" :state="validateStateSOP(index, 'identitas')" class="ml-2" placeholder="Silahkan masukkan NIK daftar pendiri organisasi"></b-form-input>
                              </b-col>
                              <b-col cols="2" class="d-flex align-items-center pl-0">
                                <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.pendiri.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pendiri.splice(-1)">mdi-minus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.pendiri.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.pendiri.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pendiri.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        
                        <b-row :class="formStruktur.pengurus.pembina && formStruktur.pengurus.pembina.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                          <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Daftar Pembina Organisasi </p></b-col
                          >:<b-col sm="8">
                            <b-row v-for="(i,index) in formStruktur.pengurus.pembina" :key="index" class="pb-2">
                              <b-col cols="10" class="pr-0 d-flex">
                                <b-form-input v-model="$v.formStruktur.pengurus.pembina.$each.$iter[index].nama_pengurus.$model" :state="validateStateSOPM(index, 'nama_pengurus')" placeholder="Silahkan masukkan daftar pembina organisasi"></b-form-input>
                                <b-form-input v-model="$v.formStruktur.pengurus.pembina.$each.$iter[index].identitas.$model" :state="validateStateSOPM(index, 'identitas')" class="ml-2" placeholder="Silahkan masukkan NIK daftar pembina organisasi"></b-form-input>
                              </b-col>
                              <b-col cols="2" class="d-flex align-items-center pl-0">
                                <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.pembina.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pembina.splice(-1)">mdi-minus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.pembina.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.pembina.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pembina.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        
                        <b-row :class="formStruktur.pengurus.penasehat && formStruktur.pengurus.penasehat.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                          <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Daftar Penasehat Organisasi </p></b-col
                          >:<b-col sm="8">
                            <b-row v-for="(i,index) in formStruktur.pengurus.penasehat" :key="index" class="pb-2">
                              <b-col cols="10" class="pr-0 d-flex">
                                <b-form-input v-model="$v.formStruktur.pengurus.penasehat.$each.$iter[index].nama_pengurus.$model" :state="validateStateSOPN(index, 'nama_pengurus')" placeholder="Silahkan masukkan daftar penasehat organisasi"></b-form-input>
                                <b-form-input v-model="$v.formStruktur.pengurus.penasehat.$each.$iter[index].identitas.$model" :state="validateStateSOPN(index, 'identitas')" class="ml-2" placeholder="Silahkan masukkan NIK daftar penasehat organisasi"></b-form-input>
                              </b-col>
                              <b-col cols="2" class="d-flex align-items-center pl-0">
                                <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.penasehat.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.penasehat.splice(-1)">mdi-minus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.penasehat.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.penasehat.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.penasehat.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        
                        <b-row :class="formStruktur.pengurus.pelindung && formStruktur.pengurus.pelindung.length == 1 ? 'pt-3 pb-3 align-items-center' : 'pt-3 pb-3 '">
                          <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Daftar Pelindung Organisasi</p></b-col
                          >:<b-col sm="8">
                            <b-row v-for="(i,index) in formStruktur.pengurus.pelindung" :key="index" class="pb-2">
                              <b-col cols="10" class="pr-0 d-flex">
                                <b-form-input v-model="formStruktur.pengurus.pelindung[index].nama_pengurus" placeholder="Silahkan masukkan daftar pelindung organisasi"></b-form-input>
                                <b-form-input v-model="formStruktur.pengurus.pelindung[index].identitas" class="ml-2" placeholder="Silahkan masukkan NIK daftar pelindung organisasi"></b-form-input>
                              </b-col>
                              <b-col cols="2" class="d-flex align-items-center pl-0">
                                <div class="button-minus" v-if="index != 0 && formStruktur.pengurus.pelindung.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pelindung.splice(-1)">mdi-minus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.pelindung.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                                <div class="button-plus" v-if="formStruktur.pengurus.pelindung.length - index == 1"><v-icon style="color:white;" small @click="formStruktur.pengurus.pelindung.push({nama_pengurus:'',identitas:''})">mdi-plus</v-icon></div>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>

                        <b-row class="pt-3 d-flex align-items-center">
                          <b-col sm="3"
                            ><p style="font-weight: 600; margin:0px;">Unggah SK Pengurus </p></b-col
                          >:<b-col sm="6">
                            <b-button v-if="file_sk_pengurus == '' || file_sk_pengurus == null" variant="primary" size="sm" @click="fileClicked2('skPengurus')"><v-icon>mdi-file-plus</v-icon> Unggah Sk Pengurus</b-button>
                            <a v-if="file_sk_pengurus !== '' && file_sk_pengurus != null" :href="fileURL + file_sk_pengurus" target="_blank" type="button">
                            <b-button variant="success" size="sm">
                              Lihat File
                            </b-button>
                            </a>
                            <b-button v-if="file_sk_pengurus != '' && file_sk_pengurus != null" @click="file_sk_pengurus = ''" variant="danger" size="sm" class="ml-3">
                              <v-icon class="p-0">mdi-delete-outline</v-icon>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row class="mt-5">
                          <b-col class="d-flex justify-content-center pt-5 mt-5">
                            <button
                              class="mt-16 text-center button-back mr-5"
                              @click="back()"
                            >
                              Kembali
                            </button>
                            <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekStruktur(formStruktur)">
                              Lanjut
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>

              <b-tab title="Rekening" style="background-color:#FAFAFA;margin-top:0px;">
                <div class="table-responsive">
                  <table class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  ">
                    <thead>
                      <tr class="text-left">
                        <th style="width: 20px">No</th>
                        <!-- <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                        <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th> -->
                        <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                        <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                        <th style="min-width: 50px" class="text-capitalize">Pemilik Rekening</th>
                        <th style="min-width: 50px" class="text-capitalize">Jenis Rekening</th>
                        <th style="width: 100px" class="text-capitalize">Aksi</th>
                      </tr>
                    </thead>
                    <tbody style="background:#FAFAFA;">
                      <template v-for="(item, index) in formRekening">
                        <tr v-bind:key="index">
                          <td>
                            <span
                              class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                              >{{ index + 1 }}</span
                            >
                          </td>
                          <!-- <td>
                            <b-form-input placeholder="Status Sekretariat"></b-form-input>
                          </td>
                          <td>
                            <b-form-input placeholder="Kabupaten/Kota"></b-form-input>
                          </td> -->
                          <td>
                            <b-form-input v-model="$v.formRekening.$each.$iter[index].no_rekening.$model" :state="validateStateRek(index, 'no_rekening')" placeholder="Nomor Rekening"></b-form-input>
                          </td>
                          <td>
                            <b-form-input v-model="$v.formRekening.$each.$iter[index].nama_bank.$model" :state="validateStateRek(index, 'nama_bank')" placeholder="Nama Bank"></b-form-input>
                          </td>
                          <td>
                            <b-form-input v-model="$v.formRekening.$each.$iter[index].nama_pemilik_rekening.$model" :state="validateStateRek(index, 'nama_pemilik_rekening')" placeholder="Pemilik Rekening"></b-form-input>
                          </td>
                          <td>
                            <b-form-select v-model="$v.formRekening.$each.$iter[index].id_jenis_rekening.$model" :state="validateStateRek(index, 'id_jenis_rekening')">
                              <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                              <b-form-select-option :value="item.id" v-for="(item,index) in listRekening" :key="index">{{item.nama}}</b-form-select-option>
                            </b-form-select>
                          </td>
                          <td class="d-flex justify-content-center">
                            <div class="button-plus" v-if="formRekening.length - index != 1" style="opacity:0.5;"><v-icon style="color:white;" small>mdi-plus</v-icon></div>
                            <div class="button-minus" v-if="index != 0 && formRekening.length - index == 1"><v-icon style="color:white;" small @click="formRekening.splice(-1)">mdi-minus</v-icon></div>
                            <div class="button-plus" v-if="formRekening.length - index == 1"><v-icon style="color:white;" small @click="addRek(index)">mdi-plus</v-icon></div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <b-row class="mt-5">
                  <b-col class="d-flex justify-content-center pt-5 mt-5">
                    <button
                      class="mt-16 text-center button-back mr-5"
                      @click="back()"
                    >
                      Kembali
                    </button>
                    <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="cekRekening()">
                      Lanjut
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Cabang Organisasi" style="background-color:#FAFAFA;margin-top:0px;">

                <section v-if="!createCabang">
                   <b-row class="mt-5">
                      <b-col class="d-flex align-items-center justify-content-between">
                        <span style="font-size:16px; font-weight:bold;">Cabang Ormas</span>
                        <div>
                            <b-button
                            size="sm"
                            variant="primary"
                            @click="addCabang()"
                          >
                            <v-icon
                              style="color:white;"
                              small
                            >mdi-plus-box</v-icon>
                            Tambah Ormas
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <div
                      class="table-responsive mt-4"
                      style="border-radius:15px;"
                    >
                          <table
                            class="
                          table
                          table-head-custom
                          table-vertical-center
                          table-head-bg
                          table-bordered
                        "
                            style="font-size:14px !important;"
                          >
                            <thead>
                              <tr class="text-left">
                                <th style="width: 20px" class="text-capitalize">No.</th>
                                <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                                <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                                <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                                <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                                <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                              </tr>
                            </thead>
                            <tbody v-if="listCabang.length == 0">
                                <template>
                                    <tr>
                                        <td colspan="6"> -- Tidak ada data -- </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody style="background:white;">
                              <template v-for="(item, i) in listCabang">
                                <tr v-bind:key="i">
                                  <td>
                                    <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  ">{{ i + 1}}</span>
                                  </td>
                                  <td>
                                    <span class="
                                    text-dark-75
                                    text-left
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  ">{{ item.m_lokasi_sekretariat_id}}</span>
                                  </td>
                                  <td>
                                    <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  " v-if="item.alamat_sekretariat">{{ item.alamat_sekretariat.length > 30 ? item.alamat_sekretariat.substring(0,30) + "..." : item.alamat_sekretariat  }}</span>
                                  </td>
                                  <td>
                                    <span class="
                                    text-dark-75
                                    font-weight-bolder
                                    d-block
                                    text-left
                                    font-size-lg
                                  " v-if="item.kabupaten_kota_sekretariat">{{ item.kabupaten_kota_sekretariat}}</span>
                                  </td>
                                  <td>
                                    <span class="
                                    text-dark-75
                                    text-left
                                    font-weight-bolder
                                    d-block
                                    font-size-lg
                                  " v-if="item.provinsi_sekretariat">{{ item.provinsi_sekretariat }}</span>
                                  </td>
                                  <td class="d-flex justify-content-center">
                                    <b-button
                                      variant="primary"
                                      size="sm"
                                      class="d-flex justify-content-center align-items-center"
                                      style="width:30px;height:30px;margin:5px;"
                                      @click="editCabang(item.id)"
                                    >
                                      <v-icon
                                        style="color:white;padding:0px;"
                                        small
                                      >mdi-pencil-outline</v-icon>
                                    </b-button>
                                    <b-button
                                      variant="danger"
                                      size="sm"
                                      class="d-flex justify-content-center align-items-center"
                                      style="width:30px;height:30px;margin:5px;"
                                      @click="deletePenambahanCabang(item.id)"
                                    >
                                      <v-icon
                                        style="color:white;padding:0px;"
                                        small
                                      >mdi-delete-outline</v-icon>
                                    </b-button>
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>
                        <b-row class="mt-5">
                          <b-col class="d-flex justify-content-center pt-5 mt-5">
                            <button
                              class="mt-16 text-center button-back mr-5"
                              @click="back()"
                            >
                              Kembali
                            </button>
                            <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="submitKelengkapan()">
                              Ajukan
                            </b-button>
                          </b-col>
                        </b-row>
                </section>
                <section v-if="createCabang">
                    <b-row>
                    <b-col cols="12">
                      <b-row class="pt-5 mt-2">
                        <b-col class="d-flex justify-content-between">
                          <b style="font-size:16px;">
                            Profil - Cabang Organisasi
                          </b>
                        </b-col>
                      </b-row>
                      <!-- <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">{{ "Nama Ormas" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-input v-model="$v.formCabang.nama_cabang.$model" :state="validateStateC('nama_cabang')" placeholder="Silahkan masukkan nama ormas"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">{{ "Singkatan" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-input v-model="$v.formCabang.singkatan.$model" :state="validateStateC('singkatan')" placeholder="Silahkan masukkan singkatan"></b-form-input>
                        </b-col>
                      </b-row> -->
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600">
                            {{ "Status Sekretariat" }} 
                          </p></b-col
                        >:<b-col sm="6">
                          <b-form-select v-model="$v.formCabang.m_lokasi_sekretariat_id.$model" :state="validateStateC('m_lokasi_sekretariat_id')">
                              <b-form-select-option value="" disabled>Silahkan masukkan status sekretariat</b-form-select-option>
                              <b-form-select-option :value="item.id" v-for="(item,index) in listSekretariat" :key="index">{{item.namaLokasi}}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600">
                            {{ "Alamat sekretariat" }} 
                          </p></b-col
                        >:<b-col sm="6">
                          <b-form-textarea
                          v-model="$v.formCabang.alamat_sekretariat.$model" :state="validateStateC('alamat_sekretariat')"
                            id="textarea"
                            placeholder="Silahkan masukkan alamat sekretariat"
                            rows="3"
                            max-rows="6"
                          ></b-form-textarea>
                        </b-col>
                      </b-row> 
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600">{{ "Propinsi" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-select v-model="$v.selectedCabangPropinsi.$model" :state="validateStateCP('selectedCabangPropinsi')">
                            <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                            <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600">{{ "Kabupaten/Kota" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-select v-model="$v.formCabang.kabupaten_kota_sekretariat.$model" :state="validateStateC('kabupaten_kota_sekretariat')">
                          <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                            {{ listKota3 ? '' : ''}}
                              <b-form-select-option :value="item.kode" v-for="(item,index) in listKota3" :key="index">{{item.nama}}</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600">{{ "Nomor Telepon" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-input v-model="$v.formCabang.no_telp.$model" :state="validateStateC('no_telp')" placeholder="Silahkan masukkan nomor telepon" description="Hanya 1 nomor telepon"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600">{{ "Nomor FAX" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-input v-model="$v.formCabang.no_fax.$model" :state="validateStateC('no_fax')" placeholder="Silahkan masukkan nomor fax" description="Hanya 1 nomor fax"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }} </p></b-col
                        >:<b-col sm="6">
                          <b-form-input v-model="$v.formCabang.npwp.$model" :state="validateStateC('npwp')" placeholder="Silahkan masukkan NPWP"></b-form-input>
                        </b-col>
                      </b-row>
                      

                      <b-row class="pt-5 mt-2">
                        <b-col>
                          <b style="font-size:16px;">
                            Info Pendirian Cabang - Cabang Organisasi
                          </b>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Nama Notaris </p></b-col
                        >:<b-col sm="4">
                          <b-form-input v-model="$v.formCabang.namaNotaris.$model" :state="validateStateC('namaNotaris')" placeholder="Silahkan masukkan nama notaris"></b-form-input>
                            </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian </p></b-col
                        >:<b-col sm="4">
                          <b-form-input v-model="$v.formCabang.nomorAkta.$model" :state="validateStateC('nomorAkta')" placeholder="Silahkan masukkan nomor akta pendirian"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Tanggal AKTA Pendirian </p></b-col
                        >:<b-col sm="4">
                          <b-form-datepicker v-model="$v.formCabang.tanggalAkta.$model" :state="validateStateC('tanggalAkta')" id="pendirian" class="mb-2"></b-form-datepicker>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Nomor SK Susunan Pengurus </p></b-col
                        >:<b-col sm="4">
                          <b-form-input v-model="$v.formCabang.nomorSuratPendaftaran.$model" :state="validateStateC('nomorSuratPendaftaran')" placeholder="Silahkan nomor SK susunan pengurus"></b-form-input>
                        </b-col>
                      </b-row>
                      <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Tempat Pendirian </p></b-col
                        >:<b-col sm="4">
                          <b-form-select v-model="$v.selectedCabangPropinsi2.$model" :state="validateStateCP2('selectedCabangPropinsi2')">
                            <b-form-select-option value="" disabled>Silahkan masukkan propinsi</b-form-select-option>
                            <b-form-select-option :value="item.kode" v-for="(item,index) in listPropinsi" :key="index">{{item.nama}}</b-form-select-option>
                          </b-form-select>
                        </b-col>
                        <b-col sm="4">
                          <b-form-select v-model="$v.formCabang.kabupatenKota.$model" :state="validateStateC('kabupatenKota')">
                            <b-form-select-option value="" disabled>Silahkan masukkan Kabupaten</b-form-select-option>
                            {{ listKota4 ? '' : ''}}
                              <b-form-select-option :value="item.kode" v-for="(item,index) in listKota4" :key="index">{{item.nama}}</b-form-select-option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                        <b-row class="pt-3 pb-3 align-items-center">
                        <b-col sm="3"
                          ><p style="font-weight: 600; margin:0px;">Tanggal Pendirian </p></b-col
                        >:<b-col sm="4">
                          <b-form-datepicker v-model="$v.formCabang.tanggalPendirian.$model" :state="validateStateC('tanggalPendirian')" id="pendiriancabang" class="mb-2"></b-form-datepicker>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>

                  <b-row class="pt-5 mt-2 pb-5">
                    <b-col>
                      <b style="font-size:16px;">
                        Struktur Organisasi - Cabang Organisasi 
                      </b>
                    </b-col>
                  </b-row>

                  <div class="table-responsive">
                    <table
                      class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              "
                    >
                      <thead>
                          <tr class="text-left">
                            <th style="width: 50px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                            <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                            <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                            <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                            <th style="width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                          </tr>
                        </thead>
                        <tbody style="background:#FAFAFA;">
                          <template v-for="(item, i) in $v.formCabang.strukturOrganisasi.$each.$iter">
                            <tr v-bind:key="i">
                              <td class="d-flex justify-content-center">
                                  <div  v-if="formCabang.strukturOrganisasi[i].foto == '' || formCabang.strukturOrganisasi[i].foto == null" @click="fileClicked2('peng_foto_cab' + i)" style="cursor:pointer;background: #C4C4C4;width: 56px;height: 56px;border-radius: 10px;display:flex; align-items:center;justify-content:center;">
                                  <v-icon>mdi-camera</v-icon>
                                </div>
                                <img v-if="formCabang.strukturOrganisasi[i].foto !== ''"
                                 @click="fileClicked2('peng_foto_cab' + i)"
                                  :src="fileURL + formCabang.strukturOrganisasi[i].foto"
                                  style="width: 56px;height: 56px;cursor:pointer;"
                                  alt="abstract"
                                />
                                </td>
                              <td>
                                  <b-form-input v-model="item.nama_pengurus.$model" :state="validateStateCS(i, 'nama_pengurus')" :placeholder="'Nama '+item.namaJabatan"></b-form-input>
                              </td>
                              <td>
                                <div class="d-flex justify-content-between align-items-center">
                                <b-form-datepicker :id="'mulaic'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                v-model="item.periode_mulai.$model" :state="validateStateCS(i, 'periode_mulai')" placeholder="Mulai" 
                                style="height:40px;width:130px;"></b-form-datepicker> - 
                                <b-form-datepicker :id="'akhirc'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                v-model="item.periode_selesai.$model" :state="validateStateCS(i, 'periode_selesai')" placeholder="Selesai" 
                                style="height:40px;width:130px;"></b-form-datepicker>
                                </div>
                              </td>
                              <td>
                                <b-form-select v-model="item.m_jabatan_id.$model" :state="validateStateCS(i, 'm_jabatan_id')">
                                  <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                                  <b-form-select-option :value="itm.id" v-for="(itm, ii) in listJabatan" :key="ii">{{itm.namaJabatan}}</b-form-select-option>
                                </b-form-select>
                              </td>
                              <td>
                                <b-form-input v-model="item.identitas.$model" :state="validateStateCS(i, 'identitas')" placeholder="No Identitas"></b-form-input>
                              </td>
                              <td>
                                <b-form-datepicker :id="'erc'+ i" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                v-model="item.tanggal_lahir.$model" :state="validateStateCS(i, 'tanggal_lahir')" placeholder="TTL" 
                                style="height:40px;width:130px;" class="mb-2"></b-form-datepicker>
                              </td>
                              <td>
                                <b-form-input placeholder="No HP" v-model="item.no_hp.$model" :state="validateStateCS(i, 'no_hp')"></b-form-input>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                    </table>
                  </div>
                  <b-row class="d-flex align-items-center">
                    <b-col sm="3"
                      ><p style="font-weight: 600; margin:0px;">Unggah SK Pengurus </p></b-col
                    >:<b-col sm="6">
                      <b-button v-if="file_sk_pengurus_cabang == '' || file_sk_pengurus_cabang == null" variant="primary" size="sm" @click="fileClicked2('skPengurusCab')"><v-icon>mdi-file-plus</v-icon> Unggah Sk Pengurus</b-button>
                      <a v-if="file_sk_pengurus_cabang !== '' && file_sk_pengurus_cabang != null" :href="fileURL + file_sk_pengurus_cabang" target="_blank" type="button">
                      <b-button variant="success" size="sm">
                        Lihat File
                      </b-button>
                      </a>
                      <b-button v-if="file_sk_pengurus_cabang != '' && file_sk_pengurus_cabang != null" @click="file_sk_pengurus_cabang = ''" variant="danger" size="sm" class="ml-3">
                        <v-icon class="p-0">mdi-delete-outline</v-icon>
                      </b-button>
                    </b-col>
                  </b-row>

                    <b-row class="pt-5 mt-2 pb-5">
                    <b-col>
                      <b style="font-size:16px;">
                        Rekening - Cabang Organisasi 
                      </b>
                    </b-col>
                  </b-row>

                  <div class="table-responsive">
                    <table class="
                      table
                      table-head-custom
                      table-vertical-center
                      table-head-bg
                      table-bordered
                    ">
                      <thead>
                          <tr class="text-left">
                            <th style="width: 20px">No</th>
                            <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                            <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                            <th style="min-width: 50px" class="text-capitalize">Pemilik Rekening</th>
                            <th style="min-width: 50px" class="text-capitalize">Jenis Rekening</th>
                            <th style="width: 100px" class="text-capitalize">Aksi</th>
                          </tr>
                        </thead>
                        <tbody style="background:#FAFAFA;">
                          <template v-for="(item, index) in $v.formRekeningC.$each.$iter">
                            <tr v-bind:key="index">
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ index + 1 }}</span
                                >
                              </td>
                              <td>
                                <b-form-input v-model="item.no_rekening.$model" :state="validateStateRekC(index, 'no_rekening')" placeholder="Nomor Rekening"></b-form-input>
                              </td>
                              <td>
                                <b-form-input v-model="item.nama_bank.$model" :state="validateStateRekC(index, 'nama_bank')" placeholder="Nama Bank"></b-form-input>
                              </td>
                              <td>
                                <b-form-input v-model="item.nama_pemilik_rekening.$model" :state="validateStateRekC(index, 'nama_pemilik_rekening')" placeholder="Pemilik Rekening"></b-form-input>
                              </td>
                              <td>
                                <b-form-select v-model="item.id_jenis_rekening.$model" :state="validateStateRekC(index, 'id_jenis_rekening')">
                                  <b-form-select-option value="" disabled>Silahkan masukkan jabatan</b-form-select-option>
                                  <b-form-select-option :value="dd.id" v-for="(dd,o) in listRekening" :key="o">{{dd.nama}}</b-form-select-option>
                                </b-form-select>
                              </td>
                              <td class="d-flex justify-content-center">
                                <div class="button-plus" v-if="formRekeningC.length - index != 1" style="opacity:0.5;">
                                  <v-icon style="color:white;" small>mdi-plus</v-icon>
                                </div>
                                <div class="button-minus" v-if="index != 0 && formRekeningC.length - index == 1">
                                  <v-icon style="color:white;" small @click="formRekeningC.splice(-1)">mdi-minus</v-icon>
                                </div>
                                <div class="button-plus" v-if="formRekeningC.length - index == 1">
                                  <v-icon style="color:white;" small @click="addRekC(index)">mdi-plus</v-icon>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                    </table>
                  </div>
                  <b-row>
                    <b-col class="d-flex justify-content-center pt-5 mt-5">
                      <button
                        class="mt-16 text-center button-back mr-5"
                        @click="createCabang = false"
                      >
                        Kembali
                      </button>
                      <b-button class="mt-16 text-center" variant="primary" size="lg" style="width:200px;" @click="saveCabang()">
                        {{createMode ? 'Tambah' : 'Ubah' }}
                      </b-button>
                    </b-col>
                  </b-row>
                </section>
                

              </b-tab>
            </b-tabs>
          </b-container>
        </div>
      </b-modal>

      <!-- modal detail penambahan -->
      <b-modal
        id="modal-profil"
        ok-only
        centered
        size="xl"
        content-class="shadow"
        hide-header
        hide-footer
      >
        <div>
          <b-container fluid class="pl-0 pr-0">
            <b-row class="pt-5 mt-2 pr-0 pl-0">
              <b-col class="d-flex justify-content-between pr-0 pl-0">
                <b style="font-size:20px;">
                  {{tabDetail == 0 ?'Profil Ormas' : ''}}
                  {{tabDetail == 1 ?'Info Pendirian Ormas' : ''}}
                  {{tabDetail == 2 ?'Struktur Organisasi' : ''}}
                  {{tabDetail == 3 ?'Dokumen Ormas' : ''}}
                  {{tabDetail == 4 ?'Rekening Ormas' : ''}}
                  {{tabDetail == 5 ?'Cabang Ormas' : ''}}
                </b>
                <div>
                  <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4" @click="$bvModal.hide('modal-profil')">
                    <v-icon class="pr-3">mdi-close</v-icon>
                    Tutup
                  </b-button>
                  <!-- <button
                    style="color:white;"
                    class="btn font-weight-bolder font-size-sm bg-hitam"
                    data-wizard-type="action-submit"
                  >
                    <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
                  </button> -->
                </div>
              </b-col>
            </b-row>
            <b-row class="pt-5">
              <b-tabs pills card justified v-model="tabDetail" style="border-radius:10px;width:100%;">
            
                <b-tab title="Profil" style="background-color:white;margin-top:0px;">
                  <b-row>
                      <b-col cols="7">
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Nama Ormas" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.nama_ormas}}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Singkatan" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.nama_singkat}}</b-col>
                        </b-row>
                        <!-- <b-row class="pt-0 pb-3 d-flex align-items-center">
                          <b-col sm="3"
                            ><p style="font-weight: 600; margin:0px;">{{ "Nomor SKT Aktif" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.no_skt}}
                            <b-button size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button>
                          </b-col>
                        </b-row> -->
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">
                              {{ "Status Sekretariat" }}
                            </p></b-col
                          >:<b-col sm="6" v-if="info_organization.masterLokasiSekretariat">{{ info_organization.masterLokasiSekretariat.nama_lokasi }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">
                              {{ "Alamat sekretariat" }}
                            </p></b-col
                          >:<b-col sm="6">{{ info_organization.alamat_sekretariat }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p></b-col
                          >:<b-col sm="6" v-if="info_organization.masterKabupatenKota">{{ info_organization.masterKabupatenKota.nama }}</b-col>
                          <b-col sm="6" v-else>{{ info_organization.kabupaten_kota_sekretariat }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Propinsi" }}</p></b-col
                          >:<b-col sm="6" v-if="info_organization.masterProvinsi">{{ info_organization.masterProvinsi.nama }}</b-col>
                          <b-col sm="6" v-else>{{ info_organization.provinsi_sekretariat }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Nomor Telepon" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.no_telp }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Nomor FAX" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.no_fax }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Email" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.email }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">
                              {{ "Tujuan organisasi" }}
                            </p></b-col
                          >:
                          <b-col sm="6">
                              {{info_organization.tujuanOrmas}}
                            </b-col>
                        </b-row>
                        
                        <b-row class="d-flex align-items-center">
                          <b-col sm="3"
                            ><p style="font-weight: 600; margin:0px;">{{ "NPWP" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.npwp }} <b-button v-if="info_organization.file_npwp != null && info_organization.file_npwp != '' " @click="newTab(fileURL + info_organization.file_npwp)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Ciri khusus" }}</p></b-col
                          >:<b-col sm="6">{{ info_organization.masterCiriKhusus.nama_ciri_khusus }}</b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Bidang Kegiatan" }}</p></b-col
                          >:<b-col sm="6"><ul class="ml-5">
                                <li v-for="(item,index) in info_organization.bidangKegiatans" :key="index">{{ item.nama_jenis_ormas }}</li>
                              </ul></b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Wadah Berhimpun" }}</p></b-col
                          >:<b-col sm="6" v-if="info_organization.wadahBerhimpun">
                            {{info_organization.wadahBerhimpun.nama_ormas}}
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="3"
                            ><p style="font-weight: 600">{{ "Badan Otonom / Sayap Organisasi" }}</p></b-col
                          >:<b-col sm="6">
                            <ul class="ml-5" v-if="info_organization.berhimpunOtonom && info_organization.berhimpunOtonom.length > 0">
                              <span v-for="(item,index) in info_organization.berhimpunOtonom" :key="index">
                              <li>{{ item.nama_ormas }}</li>
                              </span>
                            </ul>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="5">
                        <b-row class="text-right">
                        <img v-if="info_organization.file_logo !== null && info_organization.file_logo !== '' " :src="fileURL  + info_organization.file_logo"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                          />
                        <img
                          v-if="info_organization.file_logo == null || info_organization.file_logo == ''"
                          src="https://alppetro.co.id/dist/assets/images/default.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />

                        <img v-if="info_organization.file_bendera !== null && info_organization.file_bendera !== ''" :src="fileURL + info_organization.file_bendera"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />
                        <img
                        v-if="info_organization.file_bendera == null || info_organization.file_bendera == ''"
                          src="https://alppetro.co.id/dist/assets/images/default.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />
                      </b-row>
                      </b-col>
                    </b-row>
                </b-tab>
                <b-tab title="Info Pendirian" style="background-color:white;margin-top:0px;">
                  <b-row>
                    <b-col cols="7">
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">Nama Notaris</p></b-col
                        >:<b-col sm="6">{{ founder_organization.namaNotaris}} </b-col>
                      </b-row>
                      <b-row class="d-flex align-items-center">
                        <b-col sm="4"
                          ><p style="font-weight: 600; margin:0px;">Nomor AKTA Pendirian</p></b-col
                        >:<b-col sm="6">{{ founder_organization.nomorAkta }} 
                          <b-button v-if="founder_organization.file_akta != null && founder_organization.file_akta != ''" @click="newTab(founder_organization.file_akta)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Tanggal AKTA Pendirian</p></b-col
                        >:<b-col sm="6">{{ founder_organization.tanggalAkta | moment("DD - MM - YYYY") }} </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">Tempat Pendirian</p></b-col
                        >:<b-col sm="6">{{ founder_organization.tempatPendirian }} </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">Tanggal Pendirian</p></b-col
                        >:<b-col sm="6">{{ founder_organization.tanggalPendirian | moment("DD - MM - YYYY") }} </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600; margin:0px;">Nomor Surat Pendaftaran</p></b-col
                        >:<b-col sm="6">{{ founder_organization.nomorSuratPendaftaran }} 
                          <!-- <b-button size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button> -->
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Tanggal Surat Pendaftaran</p></b-col
                        >:<b-col sm="6">{{ founder_organization.tanggalSuratPendaftaran | moment("DD - MM - YYYY") }} </b-col>
                      </b-row>
                      <!-- <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Nama Kesbangpol Propinsi</p></b-col
                        >:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.nama }} </b-col>
                      </b-row>
                      <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Email Kesbangpol Propinsi</p></b-col
                        >:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.email }} </b-col>
                      </b-row>
                      <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Nomor HP Kesbangpol Propinsi</p></b-col
                        >:<b-col sm="6">{{ founder_organization.wilayahProvinsi.masterKesbangpolProv.nomorTelepon }} </b-col>
                      </b-row>

                      <b-row v-if="founder_organization.wilayahKabupatenKota.masterKesbangpolKab">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Nama Kesbangpol Kabupaten/Kota</p></b-col
                        >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.nama }} </b-col>
                      </b-row>
                      <b-row v-if="founder_organization.wilayahProvinsi.masterKesbangpolProv">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Email Kesbangpol Kabupaten/Kota</p></b-col
                        >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.email }} </b-col>
                      </b-row>
                      <b-row v-if="founder_organization.wilayahKabupatenKota.masterKesbangpolKab">
                        <b-col sm="4"
                          ><p style="font-weight: 600">Nomor HP Kesbangpol Kabupaten/Kota</p></b-col
                        >:<b-col sm="6">{{ founder_organization.wilayahKabupatenKota.masterKesbangpolKab.nomorTelepon }} </b-col>
                      </b-row> -->
                    </b-col>
                    <b-col cols="5">
                       <b-row class="text-right">
                        <img v-if="info_organization.file_logo !== null && info_organization.file_logo !== '' " :src="fileURL + info_organization.file_logo"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                          />
                        <img
                          v-if="info_organization.file_logo == null || info_organization.file_logo == ''"
                          src="https://alppetro.co.id/dist/assets/images/default.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />

                        <img v-if="info_organization.file_bendera !== null && info_organization.file_bendera !== ''" :src="fileURL + info_organization.file_bendera"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />
                        <img
                        v-if="info_organization.file_bendera == null || info_organization.file_bendera == ''"
                          src="https://alppetro.co.id/dist/assets/images/default.jpg"
                          style="width: 60%; margin-bottom: 20px; margin-left: 8px;opacity:20%;"
                          alt="abstract"
                        />
                      </b-row>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Struktur Ormas" style="background-color:white;margin-top:0px;">
                  <div class="tab-content">
                    <!--begin::Table-->
                    <div class="table-responsive">
                      <table
                        class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                "
                      >
                        <thead>
                          <tr class="text-left">
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                            <th style="width: 170px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                            <th style="width: 200px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                            <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                            <th style="width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                          </tr>
                        </thead>
                        <tbody style="background:#FAFAFA;">
                          <template v-for="(item, i) in structure_organization.pengurusInti">
                            <tr v-bind:key="i">
                              <td>
                                <img :src="fileURL + item.foto" style="width:64px;height:64px;" />  
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          "
                                  >{{ item.nama_pengurus }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          "
                                  >{{ item.periode_mulai | moment("DD/MM/YYYY") }} - {{  item.periode_selesai | moment("DD/MM/YYYY") }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ item.masterJabatan.nama_jabatan }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-left
                            text-dark-75
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{ item.identitas }}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            text-left
                            font-weight-bolder
                            d-block
                            font-size-lg
                          "
                                  >{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span
                                >
                              </td>
                              <td>
                                <span
                                  class="
                            text-dark-75
                            font-weight-bolder
                            text-left
                            d-block
                            font-size-lg
                          "
                                  >{{ item.no_hp }}</span
                                >
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                      
                    <!--end::Table-->
                    <div class="mt-5" v-if="structure_organization">
                      <b-row class="d-flex align-items-center">
                    <b-col sm="4"
                      ><p style="font-weight: 600; margin:0px;">SK Susunan Pengurus</p></b-col
                        >:<b-col sm="6"><b-button v-if="info_organization.file_sk_pengurus !== null && info_organization.file_sk_pengurus == ''" @click="newTab(fileURL + info_organization.file_sk_pengurus)" size="sm" variant="success"><v-icon style="color:white;" small>mdi-clipboard</v-icon>Lihat File Unggahan</b-button></b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pendiri Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pembina Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pembina'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Penasehat Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                      <b-row >
                        <b-col sm="4"
                          ><p style="font-weight: 600">Daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.nama_pengurus}};</span> 
                          </span> 
                          </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="4"
                          ><p style="font-weight: 600">NIK daftar Pelindung Organisasi</p></b-col
                        >:<b-col sm="6">
                          <span v-for="(item,index) in structure_organization.listPengurus" :key="index">
                          <span v-if="item.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung'">{{item.identitas}};</span> 
                          </span> 
                        </b-col>
                      </b-row>

                    </div>
                  </div>
                </b-tab>
                <b-tab title="Dokumen Ormas" style="background-color:white;margin-top:0px;">
                  <div class="table-responsive">
                    <table class="
                      table
                      table-head-custom
                      table-vertical-center
                      table-head-bg
                      table-bordered
                    ">
                      <thead>
                        <tr class="text-left text-capitalize">
                          <th style="width: 20px" class="text-capitalize">No</th>
                          <th style="min-width: 50px;" class="text-capitalize">Deskripsi</th>
                          <th style="min-width: 200px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in document_organization">
                          <tr v-bind:key="i">
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{ i + 1 }}</span
                              >
                            </td>
                            <td style="max-width:50%;">
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        "
                                >{{ item.masterJenisDokumen.nama_jenis_dokumen }}</span
                              >
                            </td>
                            <td class="d-flex justify-content-center">
                                <b-button @click="downloadItem(fileURL + item.path, item.nama_dokumen, item.tipe_dokumen)" size="sm" variant="primary" class="mr-3"><v-icon style="color:white;" small>mdi-download</v-icon>Unduh</b-button>
                                <b-button size="sm" variant="success" @click="newTab(fileURL + item.path)"><v-icon style="color:white;" small>mdi-eye</v-icon><span>Lihat</span></b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-tab>
                <b-tab title="Rekening" style="background-color:white;margin-top:0px;">
                  <div class="table-responsive">
                    <table class="
                      table
                      table-head-custom
                      table-vertical-center
                      table-head-bg
                      table-bordered
                    ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px">No</th>
                          <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                          <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                          <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                          <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item, i) in bank_account_organization">
                          <tr v-bind:key="i">
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{ i + 1 }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{item.no_rekening}}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        "
                                >{{item.nama_bank}}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        "
                                >{{item.nama_pemilik_rekening}}</span
                              >
                            </td>
                            <td>
                              <span
                                class="
                          text-dark-75
                          text-left
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                                >{{item.masterJenisRekening.nama}}</span
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-tab>
                <b-tab title="Cabang Ormas" style="background-color:white;margin-top:0px;">
                  <div
                    class="table-responsive"
                    style="border-radius:15px;"
                  >
                    <table
                      class="
                    table
                    table-head-custom
                    table-vertical-center
                    table-head-bg
                    table-bordered
                  "
                      style="font-size:14px !important;"
                    >
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px" class="text-capitalize">No.</th>
                          <th style="min-width: 50px" class="text-capitalize">Status Sekretariat</th>
                          <th style="min-width: 50px" class="text-capitalize">Alamat</th>
                          <th style="min-width: 50px" class="text-capitalize">Kabupaten/Kota</th>
                          <th style="min-width: 50px" class="text-capitalize">Propinsi</th>
                          <th style="min-width: 50px" class="text-capitalize">Status</th>
                          <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:white;">
                        <template v-for="(item, i) in listCabang">
                          <tr v-bind:key="i">
                            <td>
                              <span class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              font-size-lg
                            ">{{ i + 1}}</span>
                            </td>
                            <td>
                              <span class="
                              text-dark-75
                              text-left
                              font-weight-bolder
                              d-block
                              font-size-lg
                            ">{{ item.m_lokasi_sekretariat_id}}</span>
                            </td>
                            <td>
                              <span class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              text-left
                              font-size-lg
                            " v-if="item.alamat_sekretariat">{{ item.alamat_sekretariat.length > 30 ? item.alamat_sekretariat.substring(0,30) + "..." : item.alamat_sekretariat  }}</span>
                            </td>
                            <td>
                              <span class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              text-left
                              font-size-lg
                            " v-if="item.kabupaten_kota_sekretariat">{{ item.kabupaten_kota_sekretariat}}</span>
                            </td>
                            <td>
                              <span class="
                              text-dark-75
                              text-left
                              font-weight-bolder
                              d-block
                              font-size-lg
                            " v-if="item.provinsi_sekretariat">{{ item.provinsi_sekretariat }}</span>
                            </td>
                            <td>
                              <span class="
                              text-dark-75
                              font-weight-bolder
                              text-left
                              d-block
                              font-size-lg
                            ">{{ 'tidak ada' }}</span>
                            </td>
                            <td>
                              <b-button
                                variant="success"
                                size="sm"
                                @click="getDetailCabang(item.id)"
                              >
                                >> Detail
                              </b-button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </b-tab>
              </b-tabs>
            </b-row>
          </b-container>
        </div>
      </b-modal>


      <!-- modal detail cabang -->
      <b-modal
        id="detail-cabang"
        ok-only
        centered
        size="xl"
        content-class="shadow"
        hide-footer
        hide-header
      >
      <b-container fluid class="mb-5">
        <b-row class="pt-5 mt-2 pr-0 pl-0">
          <b-col class="d-flex justify-content-between pr-0 pl-0">
            <b style="font-size:20px;">
              Detail - Cabang Ormas
            </b>
            <div>
              <b-button variant="outline-secondary" style="background: #FFFFFF;" size="sm" class="mr-4" @click="$bvModal.hide('detail-cabang')">
                <v-icon class="pr-3">mdi-close</v-icon>
                Tutup
              </b-button>
              <b-button v-if="tabDetailCabang == 2 && file_sk_pengurus_cabang" @click="newTab(fileURL + file_sk_pengurus_cabang)" variant="primary" size="sm" class="mr-4">
                <v-icon class="pr-3">mdi-clipboard</v-icon>
                Lihat SK Pengurus
              </b-button>
              <!-- <button
                style="color:white;"
                class="btn font-weight-bolder font-size-sm bg-hitam"
                data-wizard-type="action-submit"
              >
                <i class="fas fa-file-pdf" style="color:white;"></i>Cetak PDF
              </button> -->
            </div>
          </b-col>
        </b-row>
      </b-container>
      {{detailCabang ? '' : ''}}
        <div v-if="detailCabang">
          <b-tabs
            pills
            card
            justified
            v-model="tabDetailCabang"
          >

            <b-tab
              active
              title="Profil"
              style="background-color:white;margin-top:0px;"
            >
              <b-row>
                <b-col cols="7">
                  <b-row v-if="detailCabang.nama_cabang">
                    <b-col sm="3">
                      <p style="font-weight: 600">{{ "Nama Ormas" }}</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.nama_cabang}}</b-col>
                  </b-row>
                  <b-row v-if="detailCabang.nama_singkatan">
                    <b-col sm="3">
                      <p style="font-weight: 600">{{ "Singkatan" }}</p>
                    </b-col>:<b-col sm="6">{{detailCabang.nama_singkatan}}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p style="font-weight: 600">
                        {{ "Status Sekretariat" }}
                      </p>
                    </b-col>:<b-col sm="6" v-if="detailCabang.masterLokasiSekretariat">{{ detailCabang.masterLokasiSekretariat.nama_lokasi }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p style="font-weight: 600">
                        {{ "Alamat sekretariat" }}
                      </p>
                    </b-col>:<b-col sm="6">{{ detailCabang.alamat_sekretariat }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p style="font-weight: 600">{{ "Kabupaten/Kota" }}</p>
                    </b-col>:<b-col sm="6" v-if="detailCabang.wilayahKabupaten">{{ detailCabang.wilayahKabupaten.nama }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p style="font-weight: 600">{{ "Propinsi" }}</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.wilayahProvinsi.nama }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p style="font-weight: 600">{{ "Nomor Telepon" }}</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.no_telp }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p style="font-weight: 600">{{ "Nomor FAX" }}</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.no_fax }}</b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="3">
                      <p
                        style="font-weight: 600; magrin:0px"
                      >{{ "NPWP" }}</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.npwp }}
                      <!-- <b-button size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button> -->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="5">
                  <!-- <b-row>
                    <img
                      src="../../../../assets/image/images/ormas_picture.png"
                      style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                      alt="abstract"
                    />
                    <img
                      src="../../../../assets/image/images/ormas_flag.png"
                      style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                      alt="abstract"
                    />
                  </b-row> -->
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              title="Info Pendirian"
              style="background-color:white;margin-top:0px;"
            >
              <b-row>
                <b-col cols="7">
                  <!-- <b-row v-for="(item, i) in info" :key="i">
                    <b-col sm="4"
                      ><p style="font-weight: 600">{{ item.label }}</p></b-col
                    >:<b-col sm="6">{{ item.answer }} <b-button v-if="item.label == 'Nomor AKTA Pendirian' || item.label == 'Nomor Surat Pendaftaran' " size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button></b-col>
                  </b-row> -->
                  <b-row>
                    <b-col sm="4">
                      <p style="font-weight: 600">Nama Notaris</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.namaNotaris }} </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <p
                        style="font-weight: 600; margin:0px;"
                      >Nomor AKTA Pendirian</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.nomorAkta }}
                      <!-- <b-button size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button> -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <p style="font-weight: 600">Tanggal AKTA Pendirian</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.tanggalAkta | moment("DD - MM - YYYY")}} </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <p
                        style="font-weight: 600; margin:0px;"
                      >Nomor SK Susunan Pengurus</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.nomorAkta }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <p style="font-weight: 600">Tempat Pendirian</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.wilayahProvinsi ? detailCabang.infoPendirian.wilayahProvinsi.nama + ',': '' }} 
                      {{ detailCabang.infoPendirian.wilayahKabupatenKota ? detailCabang.infoPendirian.wilayahKabupatenKota.nama + ',': '' }} </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4">
                      <p style="font-weight: 600">Tanggal Pendirian</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.tanggalPendirian | moment("DD - MM - YYYY") }} </b-col>
                  </b-row>
                  <b-row v-if="detailCabang.infoPendirian.masterKesbangpol">
                    <b-col sm="4">
                      <p style="font-weight: 600">Nama Kesbangpol</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.nama }} </b-col>
                  </b-row>
                  <b-row v-if="detailCabang.infoPendirian.masterKesbangpol">
                    <b-col sm="4">
                      <p style="font-weight: 600">Email Kesbangpol</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.email }} </b-col>
                  </b-row>
                  <b-row v-if="detailCabang.infoPendirian.masterKesbangpol">
                    <b-col sm="4">
                      <p style="font-weight: 600">Nomor HP Kesbangpol</p>
                    </b-col>:<b-col sm="6">{{ detailCabang.infoPendirian.masterKesbangpol.nomorTelepon }} </b-col>
                  </b-row>
                </b-col>
                <b-col cols="5">
                  <!-- <b-row>
                    <img
                      src="../../../../assets/image/images/ormas_picture.png"
                      style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                      alt="abstract"
                    />
                    <img
                      src="../../../../assets/image/images/ormas_flag.png"
                      style="width: 80%; margin-bottom: 20px; margin-left: 8px; opacity:20%;"
                      alt="abstract"
                    />
                  </b-row> -->
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              title="Struktur Pengurus"
              style="background-color:white;margin-top:0px; padding-left:0px; padding-right:0px;"
            >
              <b-card-text class="pl-0 pr-0">
                <div class="card-body pt-0 pb-3 pl-0 pr-0">
                  <div class="tab-content">
                    <!--begin::Table-->
                    <div class="table-responsive">
                      <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                        <thead>
                          <tr class="text-left">
                            <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Foto</th>
                            <th style="min-width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Nama</th>
                            <th style="min-width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Masa Bakti</th>
                            <th style="width: 90px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Jabatan</th>
                            <th style="width: 150px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. Identitas</th>
                            <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">Tanggal Lahir</th>
                            <th style="min-width: 100px; padding-left:0px; padding-right: 0px;" class="text-capitalize">No. HP</th>
                          </tr>
                        </thead>
                        <tbody style="background:#FAFAFA;">
                          <template v-for="(item, i) in detailCabang.strukturOrganisasi">
                            <tr v-bind:key="i">
                              <td>
                              <img :src="fileURL + item.foto" style="width:64px;height:64px;" />  
                              </td>
                              <td>
                                <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.nama_pengurus }}</span>
                              </td>
                              <td>
                                <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.periode_mulai}} - {{ item.periode_selesai}}</span>
                              </td>
                              <td>
                                <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.masterJabatan.nama_jabatan }}</span>
                              </td>
                              <td>
                                <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.identitas }}</span>
                              </td>
                              <td>
                                <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{item.tanggal_lahir | moment("DD-MM-YYYY")}}</span>
                              </td>
                              <td>
                                <span class="
                            text-dark-75
                            font-weight-bolder
                            d-block
                            text-left
                            font-size-lg
                          ">{{ item.no_hp }}</span>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
            <b-tab
              title="Rekening"
              style="background-color:white;margin-top:0px;"
            >
              <div class="table-responsive">
                <table class="
                  table
                  table-head-custom
                  table-vertical-center
                  table-head-bg
                  table-bordered
                ">
                  <thead>
                    <tr class="text-left">
                      <th style="width: 20px" class="text-capitalize">No</th>
                      <th style="min-width: 50px" class="text-capitalize">Nomor Rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Nama Bank</th>
                      <th style="min-width: 50px" class="text-capitalize">Pemilik rekening</th>
                      <th style="min-width: 50px" class="text-capitalize">Jenis rekening</th>
                    </tr>
                  </thead>
                  <tbody style="background:#FAFAFA;">
                    <template v-for="(item, i) in detailCabang.rekeningOrmas">
                      <tr v-bind:key="i">
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{ i + 1 }}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.no_rekening}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.nama_bank}}</span>
                        </td>
                        <td>
                          <span class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.nama_pemilik_rekening}}</span>
                        </td>
                        <td>
                          <span v-if="item.masterJenisRekening" class="
                      text-dark-75
                      font-weight-bolder
                      d-block
                      text-left
                      font-size-lg
                    ">{{item.masterJenisRekening.nama}}</span>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    <!-- index table -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center p-1 pr-3" style="border-radius:8px; border:solid 1px;">
                  <input
                    type="text"
                    class="form-control border-0"
                    :placeholder="'Cari...'"
                    v-model="search"
                    v-on:keyup.enter="searchSubmit()"
                  />
                  <b-button
                    size="sm"
                    style="width:80px;margin-right:10px;"
                    variant="primary"
                    @click="searchSubmit()"
                  >
                    <v-icon
                      style="color:white;"
                      small
                    >mdi-magnify</v-icon>
                    Cari
                  </b-button>
                </div>
              </b-col>
              <!-- <b-col cols="1" class="d-flex align-items-center p-0">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="showFilter()"
                >
                  <v-icon
                    style="color:white;"
                    small
                  >mdi-filter</v-icon>
                  Filter
                </b-button>
              </b-col> -->
            </b-row>
            <!-- </b-card> -->
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col class="d-flex align-items-center justify-content-between">
            <span style="font-size:16px; font-weight:bold;">Penambahan Ormas</span>
            <div>
                <b-button
                size="sm"
                variant="primary"
                @click="tambahOrmas()"
              >
                <v-icon
                  style="color:white;"
                  small
                >mdi-plus-box</v-icon>
                Tambah Ormas
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div
              class="table-responsive mt-4"
              style="border-radius:15px;"
            >
              <table class="table table-head-custom table-vertical-center table-head-bg table-bordered" style="font-size:14px !important;">
                <thead>
                  <tr class="text-left">
                    <th style="width: 20px" class="text-capitalize">No.</th>
                    <th style="min-width: 50px" class="text-capitalize">Nama Ormas</th>
                    <th style="min-width: 50px" class="text-capitalize" v-if="userType != 'provinsi' && userType != 'admin'">Singkatan</th>
                    <th style="min-width: 50px" class="text-capitalize" >Status Sekretariat</th>
                    <th style="min-width: 50px" class="text-capitalize">Status Pelaporan</th>
                    <th style="min-width: 50px" class="text-capitalize">Status Kegiatan</th>
                    <th style="min-width: 50px" class="text-capitalize" v-if="userType == 'provinsi'">Kabupaten/Kota</th>
                    <th style="min-width: 50px" class="text-capitalize" v-if="userType == 'admin'">Propinsi</th>
                    <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                  </tr>
                </thead>
               <tbody v-if="listOrmas.length == 0">
                    <template v-if="!loadingTable">
                        <tr>
                            <td colspan="6"> -- Tidak ada permohonan -- </td>
                        </tr>
                    </template>
                    <template v-if="loadingTable">
                        <tr>
                            <td colspan="6"> <b-spinner size="sm" variant="primary" color="primary"></b-spinner></td>
                        </tr>
                    </template>
                </tbody>
                <tbody style="background: #FFFFFF;">
                  <template v-for="(item, i) in listOrmas">
                    <tr v-bind:key="i">
                      <td >
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                          {{(i + 1) + ((currentPage - 1)*perPage)}}
                        </span>
                      </td>
                      <td class="text-left">
                        <span  class="text-dark-75 font-weight-bolder text-left font-size-lg">
                          {{item.nama_ormas}}
                        </span>
                      </td>
                      <td class="text-left" v-if="userType != 'provinsi' && userType != 'admin'">
                        <span v-if="item.nama_singkat == null" class="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                          -
                        </span>
                        <span v-else class=" text-dark-75 font-weight-bolder d-block text-left font-size-lg">
                          {{item.nama_singkat}}
                        </span>
                      </td>
                      <td class="text-left">
                        <span v-if="item.masterLokasiSekretariat == null" class="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                          -
                        </span>
                        <span v-else class="text-dark-75 font-weight-bolder d-block text-left font-size-lg">
                          {{item.masterLokasiSekretariat.nama_lokasi}}
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-dark-75 font-weight-bolder d-block text-left font-size-lg">
                          Belum Lapor
                        </span>
                      </td>
                      <td class="text-left">
                        <span class="text-dark-75 font-weight-bolder d-block text-left font-size-lg">
                          Aktif
                        </span>
                      </td>
                      <td v-if="userType == 'provinsi'" class="text-left">
                        <span class="text-dark-75 font-weight-bolder d-block text-left font-size-lg">
                          {{item.masterKabupatenKota.nama}}
                        </span>
                      </td>
                      <td v-if="userType == 'admin'" class="text-left">
                        <span class="text-dark-75 font-weight-bolder d-block text-left font-size-lg">
                          {{item.masterProvinsi.nama}}
                        </span>
                      </td>
                       <td>
                        <section class="d-flex justify-content-center align-items-center">
                        <b-button
                          variant="primary"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                          @click="editPenambahan(item.id)"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-pencil-outline</v-icon>
                        </b-button>
                        <b-button
                          variant="danger"
                          size="sm"
                          class="d-flex justify-content-center align-items-center"
                          style="width:30px;height:30px;margin:5px;"
                          @click="deletePenambahan(item.id)"
                        >
                          <v-icon
                            style="color:white;padding:0px;"
                            small
                          >mdi-delete-outline</v-icon>
                        </b-button>
                        <b-button
                        @click="toDetail(item.id)"
                          class="d-flex justify-content-center align-items-center"
                          variant="success"
                          size="sm"
                          style="width:30px;height:30px;margin:5px;"
                        >
                          >>
                        </b-button>
                        </section>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="my-table"></b-pagination>
              </b-col>
            </b-row>
      </section>
    </div>
</template>
<style>
#search-card .card-body {
  padding:0px !important;
}
</style>
<script>

import { validationMixin } from "vuelidate";
import { required, email, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from "axios";
export default {
  mixins: [validationMixin],
  name: "form_tambah_ormas",
  data() {
    return {
      search: '',
      userType: '',
      totalRows: 0,
      currentPage: 1,
      perPage: 20,
      jenis_file: '',
      file_logo: '',
      file_bendera: '',
      file_skt: '',
      file_npwp: '',
      file_sk_pengurus: '',
      file_sk_pengurus_cabang: '',
      loadingTable: false,
      createCabang: false,
      fileURL: process.env.VUE_APP_APPLICATION_FILE_URL + '/',
      editPenambahanMode: false,
      listDoc: [],
      disProp: false,
      disKab: false,
      idPenambahan: null,
      tabDetail: 0,
      listOrmas: [],
      statDis: {},
      bidang_kegiatans: [''],
      daftar_pendiri: [''],
      progress:0,
      daftar_pembinan: [''],
      daftar_penasehat: [''],
      daftar_pelindung: [''],
      rekening: [''],
      rekeningcabang: [''],
      yearMB: [],
      yearMBend:[[],[],[]],
      query: {},
      currentOffset: 0,
      info_organization: {
        "id": null,
        "nama_ormas": "",
        "nama_singkat": "",
        "npwp": "",
        "m_kategori_ormas_id": null,
        "alamat_sekretariat": "",
        "no_skt": "",
        "tanggal_skt": "",
        "tanggal_berdiri": "",
        "email": "",
        "no_telp": "",
        "no_fax": "",
        "website": "",
        "status_ormas": null,
        "status_badan_hukum": null,
        "status_legalitas": null,
        "sys_user_id": null,
        "m_lokasi_sekretariat_id": null,
        "provinsi_sekretariat": "",
        "kabupaten_kota_sekretariat": "",
        "m_ciri_khusus_id": null,
        berhimpunOtonom: [{nama_ormas: ''}],
        wadahBerhimpun: {nama_ormas: ''},
        "bidangKegiatans": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": "",
            "m_jenis_ormas_id": null,
            "nama_jenis_ormas": "",
          }
        ],
        "tujuanOrmas": [
          {
            "id": null,
            "data_ormas_id": null,
            "keterangan": ""
          }
        ],
        "masterProvinsi": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterKabupatenKota": {
          "id": null,
          "kode": "",
          "nama": "",
          "tipe": null,
          "latlong": null,
          "terhapus": false
        },
        "masterCiriKhusus": {
          "id": null,
          "nama_ciri_khusus": "",
          "terhapus": 0
        }
      },
      founder_organization: {
        "id": null,
        "dataOrmasId": null,
        "cabangOrmasId": null,
        "nomorAkta": "",
        file_akta: '',
        "tanggalAkta": "",
        "tempatPendirian": {
          nama: ''
        },
        "tanggalPendirian": "",
        "nomorSuratPendaftaran": "",
        "tanggalSuratPendaftaran": "",
        "m_kesbangpol_id": null,
        wilayahProvinsi: {
          masterKesbangpolProv: {
            nama: '',
            email: '',
            noTelepon: ''
          }
        },
        wilayahKabupatenKota: {
          masterKesbangpolKab: {
            nama: '',
            email: '',
            noTelpon: ''
          }
        }
      },
      structure_organization: {
        "pengurusInti": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }],
        "listPengurus": [{
          "id": null,
          "data_ormas_id": null,
          "cabang_ormas_id": null,
          "nama_pengurus": "",
          "m_jenis_identitas_id": null,
          "identitas": "",
          
          "tanggal_lahir": "",
          "alamat": "",
          "provinsi": "",
          "kabupaten_kota": "",
          "no_telp": "",
          "no_hp": "",
          "periode_mulai": "",
          "periode_selesai": "",
          "status_pengurus": null,
          "keterangan": "",
          "foto": "",
          "m_kategori_pengurus_id": {},
          "m_jabatan_id": {},
          "masterKategoriPengurus": {
            "id": null,
            "nama_kategori_pengurus": "",
            "terhapus": null,
            "kode_kategori_pengurus": ""
          },
          "masterJabatan": {
            "id": null,
            "nama_jabatan": "",
            "terhapus": null,
            "kode_jabatan": ""
          }
        }]
      },
      document_organization: [
          {
          "id": null,
          "nama_jenis_dokumen": "",
          "terhapus": null,
          masterJenisDokumen: {nama_jenis_dokumen: ''},
          "kode": ""
          }
        ],
      bank_account_organization: [
        {
        "id": null,
        "data_ormas_id": null,
        "cabang_id": null,
        "no_rekening": "",
        "nama_bank": "",
        "cabang_bank": "",
        "nama_pemilik_rekening": "",
        masterJenisRekening: {nama: ''},
        "id_jenis_rekening": ""
        },
      ],
      activeTab: 0,
      formProfil: {
          file_esign_skt: null,
          file_scan_notaris: null,
          file_npwp: null,
          file_scan_surat_permohonan: null,
          nama_ormas: '',
          singkatan: '',
          // no_skt: '',
          status_sekretariat: null,
          alamat_sekretariat: '',
          kabupaten_kota_sekretariat: '',
          provinsi_sekretariat: '',
          no_telp: '',
          no_fax: '',
          email: '',
          tujuan_ormas: '',
          npwp: '',
          m_ciri_khusus_id: '',
          bidang_kegiatan: [{id: ''}],
          wadahBerhimpun: '',
          badan_otonom: [{id: ''}]
      },
      formPendiri: {
        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: "",
        tanggalPendirian: "",
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: "",
        provinsi: '',
        kabupatenKota: '',
        file_akta: ''
      },
      formStruktur: {
        file_sk_pengurus: '',
        pengurusInti:[
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
          }
        ],
        pengurus: {
          pendiri: [
            {
              nama_pengurus: "",
              identitas: ""
            }
          ],
          pembina: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ],
          penasehat: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ],
          pelindung: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ]
        }
      },
      formRekening: [
        {
          no_rekening: '',
          nama_bank: '',
          cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening: null
        }
      ],
      formCabang: {
        nama_cabang: '',
        no_telp: '',
        singkatan:'',
        no_fax: '',
        alamat_sekretariat: '',
        npwp: '',
        tanggal_berdiri: '',
        kabupaten_kota_sekretariat: '',
        m_lokasi_sekretariat_id: '',

        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: '',
        kabupatenKota: '',
        tanggalPendirian: '',
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: '-',
        strukturOrganisasi: [
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: ''
          }
        ]
      },
      formRekeningC: [
        {
          no_rekening: '',
          nama_bank: '',
          // cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening:''  
        }
      ],
      vProfile: false,
      vPendiri: false,
      vStruktur: false,
      vRekening: false,
      vCabang: false,
      listSekretariat:[],
      listPropinsi: [],
      listKotaProfil: [],
      listKota: [],
      listKota2: [],
      listKota3: [],
      listKota4: [],
      listCiriKhusus: [],
      listBidangKegiatan: [],
      listBadanOtonom: [],
      listJabatan: [],
      listRekening: [],
      listDokumen: [],
      selectedPropinsi: '',
      selectedPendiriPropinsi: '',
      selectedCabangPropinsi: '',
      selectedCabangPropinsi2: '',
      listCabang: [],
      createMode: true,
      idCabang: '',
      tabDetailCabang: 0,
      detailCabang: null,
      rules: '',
      selectedDoc: null,
      loadUpload :false,
      documentUploadedList: [],
    }
  },
  validations: {
    selectedPropinsi: {required},
    selectedPendiriPropinsi: {required},
    formProfil: {
      nama_ormas: {
        required
      },
      singkatan: {
        required
      },
      //     no_skt: {
      //   required
      // },
          status_sekretariat: {
        required
      },
          alamat_sekretariat: {
        required
      },
          kabupaten_kota_sekretariat: {
        required
      },
      no_telp: {
        required,
        maxLength: maxLength(13)
      },
      no_fax: {
        required,
        maxLength: maxLength(12)
      },
          email: {
        required,
        email
      },
          tujuan_ormas: {
        required
      },
          npwp: {
        required
      },
          m_ciri_khusus_id: {
        required
      },
      wadahBerhimpun: {
        required
      },
      bidang_kegiatan: {
        $each: {
          id:{required}
        }
      },
      badan_otonom: {
        $each: {
          id: {required}
        }
      }   
          // badan_otonom: [''],
    },
    formPendiri: {
        namaNotaris: {
          required
        },
        nomorAkta: {
          required
        },
        tanggalAkta: {
          required
        },
        // "2021-09-30T03:59:56.529Z",
        tanggalPendirian: {
          required
        },
        nomorSuratPendaftaran: {
          required
        },
        tanggalSuratPendaftaran: {
          required
        },
        kabupatenKota: {
          required
        },
    },
    formStruktur: {
      pengurusInti:{
        $each: {
          nama_pengurus: {required},
          periode_mulai: {required},
          periode_selesai: {required},
          m_jabatan_id: {required},
          identitas: {required},
          tanggal_lahir: {required},
          no_hp: {required}
        }
      },
      pengurus: {
        pendiri: {
          $each: {
            nama_pengurus: {required},
            identitas: {required}
          }
        },
        pembina: {
          $each: {
            nama_pengurus:  {required},
            identitas:  {required}
          }
        },
        penasehat: {
          $each: {
            nama_pengurus:  {required},
            identitas:  {required}
          }
        },
      }
    },
    formRekening: {
      $each: {
        no_rekening: {required},
        nama_bank: {required},
        // cabang_bank: {required},
        nama_pemilik_rekening: {required},
        id_jenis_rekening: {required}
      }
    },
    formCabang: {
        // nama_cabang: {required},
        no_telp: {
          required,
          maxLength: maxLength(13)
        },
        // singkatan: {required},
        no_fax: {
          required,
          maxLength: maxLength(12)
        },
        alamat_sekretariat: {required},
        npwp: {required},
        kabupaten_kota_sekretariat: {required},
        m_lokasi_sekretariat_id: {required},

        namaNotaris: {required},
        nomorAkta: {required},
        tanggalAkta: {required},
        kabupatenKota: {required},
        tanggalPendirian: {required},
        nomorSuratPendaftaran: {required},
        strukturOrganisasi: {
          $each: {
            nama_pengurus: {required},
            periode_mulai: {required},
            periode_selesai: {required},
            m_jabatan_id: {required},
            identitas: {required},
            tanggal_lahir: {required},
            no_hp: {required}
          }
        },
      },
      formRekeningC: {
        $each: {
          no_rekening: {required},
          nama_bank: {required},
          nama_pemilik_rekening: {required},
          id_jenis_rekening: {required}
        }
      },
      selectedCabangPropinsi: {required},
      selectedCabangPropinsi2: {required},
  },
  mounted() {
    if(localStorage.getItem('profile')){
      var dd = JSON.parse(localStorage.getItem('profile')).profilUser
      if(dd.m_kesbangpol_id !== null){
        this.kodeProv = dd.masterKesbangpol.provinsi_kode
        this.kodeKabKot = dd.masterKesbangpol.kabupaten_kota_kode
        // const paramGetWilayahUser = {
        //   where: {or: [{kode: this.kodeProv}, {kode: this.kodeKabKot}]}
        // }
        // this.$store.dispatch('wilayahCustomFilter', paramGetWilayahUser).then((res) => {
        // })
        if(dd && dd.masterKesbangpol && dd.masterKesbangpol.kabupaten_kota_kode !== null){
          this.userType = 'kabupaten'
        }else{
          this.userType = 'provinsi'
        }
      }else{
        // this.kodeProv = ''
        // this.kodeKabKot = ''
        this.userType = 'admin'
      }      
    }
     this.getListOrmas()
     this.getCiriKhusus()
     this.getStatusSekretariat()
     this.getPropinsi()
     this.getBidangKegiatan()
     this.getBadanOtonom()
     this.getJabatan()
     this.getJenisRekening()

    var d = new Date();
    var n = d.getFullYear();
    var nn = n - 2000 + 1
    for(var i = 0; i < nn; i++){
      if (i < 10) this.yearMB.push('200' + i)
      else this.yearMB.push('20' + i)
    }
  },
  watch:{
    selectedPropinsi(to){
      this.getKotaProfil(to)
    },

    selectedPendiriPropinsi(to){
      this.getKotaProfil2(to, this.formPendiri.kabupatenKota)
    },
    
    selectedCabangPropinsi(to){
      this.getKota3(to)
    },
    selectedCabangPropinsi2(to){
      this.getKota4(to)
    },
    activeTab(to){
      if (to == 5) {
        let idnya = localStorage.getItem('idPenambahanOrmas')
        this.getListCabang(idnya)
      } else if( to == 2) {
        this.getListDokumen()
        this.editPenambahan(localStorage.getItem('idPenambahanOrmas'))
      } else if( to == 4 || to == 3) {
        this.editPenambahan(localStorage.getItem('idPenambahanOrmas'))
      }
    },
    tabDetail(to){
      if (to == 5) {
        this.getListCabang(this.idPenambahan)
      }
    },
    currentPage(to){
      this.currentOffset = to - 1
      this.getListOrmas()
    },
    search: {
      handler() {
        // Check if the user has stopped typing
        if (this.typingTimeout) {
          clearTimeout(this.typingTimeout);
        }
        this.typingTimeout = setTimeout(() => {
          // User has stopped typing, perform your action here
          this.searchSubmit()

        }, 500); // Adjust the delay as needed (1 second in this example)
      },
      immediate: false, // Set to true if you want to trigger it immediately
    }
  },
  methods: {
    async downloadItem(url, label, typeD) {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: typeD });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    replaceByDefault(e) {
      e.target.src = "/assets/images/default-thumbnail.jpg"
    },
    fileClicked(item){
      this.$nextTick(()=>{
        this.rules = item.rules.accept.join(',')
        this.selectedDoc = item
      })
      this.$forceUpdate()
      setTimeout(() => {
        this.$refs.fileInput.click()  
      }, 500);
    },
    fileClicked2(item){
      this.jenis_file = item
      setTimeout(() => {
        this.$refs.fileInput2.click()  
      }, 500);
    },
    // getUserType(){
    //   var prof = JSON.parse(localStorage.getItem('profile'))

    //   if(prof.profilUser && prof.profilUser.masterKesbangpol && prof.profilUser.masterKesbangpol.kabupaten_kota_kode == null){
    //     this.userType = 'provinsi'
    //     this.getKota(Number(prof.profilUser.masterKesbangpol.provinsi_kode))
    //   }else if(prof.profilUser && prof.profilUser.masterKesbangpol && prof.profilUser.masterKesbangpol.kabupaten_kota_kode != null){
    //     this.userType = 'kabupaten'
    //   } else {
    //     this.userType = 'admin'
    //   }
    // },
    uploadFile2(e){
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > 15000) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.$store.dispatch('uploadFileGeneral', formData).then((res) =>{

        if(res.data.status != false){
          // "path": res.data.data.newFilePath,
          Swal.fire({
            title: "Upload File Berhasil",
            text: '',
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
            if(this.jenis_file == 'logo') this.file_logo = res.data.data.newFilePath
            if(this.jenis_file == 'bendera') this.file_bendera = res.data.data.newFilePath
            if(this.jenis_file == 'skt') this.file_skt = res.data.data.newFilePath
            if(this.jenis_file == 'npwp') this.file_npwp = res.data.data.newFilePath
            if(this.jenis_file == 'scanNotaris') this.formPendiri.file_akta = res.data.data.newFilePath
            if(this.jenis_file == 'skPengurus'){ 
              this.file_sk_pengurus = res.data.data.newFilePath
              // this.cekProfile('f')
            }
            if(this.jenis_file == 'skPengurusCab') this.file_sk_pengurus_cabang = res.data.data.newFilePath
            for(var i = 0; i < 3; i++){
              if(this.jenis_file == 'peng_foto' + i) this.formStruktur.pengurusInti[i].foto = res.data.data.newFilePath
              if(this.jenis_file == 'peng_ktp' + i) this.formStruktur.pengurusInti[i].file_identitas = res.data.data.newFilePath
            }
            for(var a = 0; a < 3; a++){
              if(this.jenis_file == 'peng_foto_cab' + a) this.formCabang.strukturOrganisasi[a].foto = res.data.data.newFilePath
            }
            this.$forceUpdate()
          })
        }
        this.$refs.fileInput2.value=null;
      }).catch((err) =>{
        this.$refs.fileInput2.value=null;
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
      })
    },
    uploadFile(e){
      this.loadUpload = true
      var calculateSize = Math.floor(e.target.files[0].size / 1024)
      if(calculateSize > this.selectedDoc.rules.fileSize) {
        Swal.fire({
          title: "Gagal Upload",
          text: 'Ukuran File Terlalu Besar',
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.loadUpload = false
        return false
      }
      
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("kodeDokumen", this.selectedDoc.kode);
      this.$store.dispatch('uploadDocument', formData).then((res) =>{

        if(res.data.status != false){
          this.documentUploadedList.push({
                "nama_dokumen": res.data.data.fileName,
                "path": res.data.data.newFilePath,
                "m_jenis_dokumen_kode": this.selectedDoc.kode,
                "tipe_dokumen": res.data.data.fileInfo.mimetype
              })
          var body = {
            "dokumenOrmas": this.documentUploadedList
          }

          this.$store.dispatch('submitDocument', body).then((res) =>{
            if(res.data.status == true){
              this.loadUpload = false
              Swal.fire({
                title: "Upload File Berhasil",
                text: '',
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              }).then(() => {
                this.getListDokumen()
                this.editPenambahan(localStorage.getItem('idPenambahanOrmas'))
                this.$forceUpdate()
              })
            }
          })

        } else {
          this.loadUpload = false
          Swal.fire({
            title: "Upload File Gagal",
            text: res.data.message,
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then(() => {
          })
          this.$refs.fileInput.value=null;
        }

        this.$refs.fileInput.value=null;
      }).catch((err) =>{
        this.loadUpload = false
        Swal.fire({
          title: "Upload File Gagal",
          text: err.message,
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
        })
        this.$refs.fileInput.value=null;
      })
    },
    cekUploaded(id){
      if(this.listDoc){
        var dd = this.listDoc.find(val => {
          return val.masterJenisDokumen.id === id
        })
        if(dd){
          return true
        } else{ return false}
      }
    },
    seeUploaded(id){
      var dd = this.listDoc.find(val => {
        return val.masterJenisDokumen.id === id
      })
      if(dd){
        this.newTab(this.fileURL + dd.path)
      }
    },
    tambahOrmas(){
      this.$v.$reset()
      this.$store.dispatch('getProfile').then((res) =>{
        this.editPenambahanMode = false
        if(res.data.data.profilUser.masterKesbangpol){

          this.selectedPropinsi = res.data.data.profilUser.masterKesbangpol.provinsi_kode
          this.selectedPendiriPropinsi = res.data.data.profilUser.masterKesbangpol.provinsi_kode
          if(this.selectedPropinsi != null && this.selectedPendiriPropinsi != null) this.disProp = true
          this.formPendiri.kabupatenKota = res.data.data.profilUser.masterKesbangpol.kabupaten_kota_kode
          this.formProfil.kabupaten_kota_sekretariat = res.data.data.profilUser.masterKesbangpol.kabupaten_kota_kode
          if(this.formPendiri.kabupatenKota != null && this.formProfil.kabupaten_kota_sekretariat != null) {
            this.disKab = true
          this.getKotaProfil(this.selectedPropinsi, this.formProfil.kabupaten_kota_sekretariat)
          this.getKotaProfil2(this.selectedPendiriPropinsi,this.formPendiri.kabupatenKota)
          }else{
            this.getKotaProfil(this.selectedPropinsi,'')
          this.getKotaProfil2(this.selectedPendiriPropinsi,'')
          }
        }
        this.resetForm()
        this.$bvModal.show('modal-form-ormas')
      })
      
    },
    deletePenambahan(id){
      const context = this
      Swal.fire({
            title: "Apakah Anda Yakin Ingin Menghapus Penambahan Ormas?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Hapus",
          }).then((result) => {
            if (result.isConfirmed) {
              context.$store.dispatch("deletePenambahan", id).then((/*res*/) => {
                Swal.fire({
                  title: "Penambahan Ormas berhasil dihapus",
                  text: "",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Lanjut",
                }).then(() => {
                  context.getListOrmas()
                })
              })
            }
          });
    },
    deletePenambahanCabang(id){
      const context = this
      Swal.fire({
            title: "Apakah Anda Yakin Ingin Menghapus Cabang Ormas?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Hapus",
          }).then((result) => {
            if (result.isConfirmed) {
              context.$store.dispatch("deleteCabangPenambahan", id).then((/*res*/) => {
                Swal.fire({
                  title: "Cabang Ormas berhasil dihapus",
                  text: "",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Lanjut",
                }).then(() => {
                  this.getListCabang(this.idPenambahan)
                })
              })
            }
          });
    },
    async getCountListOrmas(q) {
      this.$store.dispatch("getListPenambahanOrmasCount", q).then((res) => {
          this.totalRows = res.data.count
      })
    },
    getListOrmas(){
      this.loadingTable = true
      this.query.type = '0';
      this.getCountListOrmas(this.query)
      this.query.offset = this.currentOffset;
      this.$store.dispatch("getListPenambahanOrmas",this.query).then((res) => {
        this.$nextTick(() => {
          this.loadingTable = false
          this.listOrmas = res.data.data
        })
      })
    },
    getListCabang(id){
      this.$store
        .dispatch("getListCabangPenambahan", id)
        .then((res) => {
          this.$nextTick(() => {
            this.listCabang = res.data.data
          })
          })
    },
    editPenambahan(id){
      this.$store
      .dispatch("getDetailPenambahanOrmas", id)
      .then((res) => {

      try{
        this.editPenambahanMode = true
        localStorage.setItem('idPenambahanOrmas',res.data.data.id)
        this.idPenambahan = res.data.data.id
        this.getListCabang(res.data.data.id)
        let d = res.data.data

        if(res.data.data.dokumenOrmas) this.listDoc = res.data.data.dokumenOrmas
        if(this.listDoc.length > 0){
          this.documentUploadedList = []
          for(var dc = 0; dc < this.listDoc.length; dc++)  {
            this.documentUploadedList.push({
              "nama_dokumen": this.listDoc[dc].nama_dokumen,
              "path": this.listDoc[dc].path,
              "m_jenis_dokumen_kode": this.listDoc[dc].masterJenisDokumen.kode,
              "tipe_dokumen": this.listDoc[dc].tipe_dokumen
            })
          }
        } else {
          this.documentUploadedList = []
          this.listDoc = []
        }
        

        var bk = []
        if(d.bidangKegiatan && d.bidangKegiatan.length > 0){
          for(var i = 0; i < d.bidangKegiatan.length; i++){
            bk.push({ id: d.bidangKegiatan[i].id})
          }
        } else {
          bk = [{id : ''}]
        }

        var bot = []
        if(d.badanOtonom && d.badanOtonom.length > 0){
          for(var ii = 0; ii < d.badanOtonom.length; ii++){
            bot.push({ id: d.badanOtonom[ii].id})
          }
        } else {
          bot = [{id : ''}]
        }

        this.formProfil = {
          // file_esign_skt: null,
          // file_scan_notaris: null,
          file_npwp: d.file_npwp,
          // file_scan_surat_permohonan: null,
          nama_ormas: d.nama_ormas,
          singkatan: d.nama_singkat,
          // no_skt: '',
          status_sekretariat: d.m_lokasi_sekretariat_id,
          alamat_sekretariat: d.alamat_sekretariat,
          kabupaten_kota_sekretariat: d.kabupaten_kota_sekretariat,
          // provinsi_sekretariat: d.,
          no_telp: d.no_telp,
          no_fax: d.no_fax,
          email: d.email,
          tujuan_ormas: d.tujuanOrmas,
          npwp: d.npwp,
          m_ciri_khusus_id: d.m_ciri_khusus_id,
          bidang_kegiatan: bk,
          wadahBerhimpun: d.wadahBerhimpun ? d.wadahBerhimpun.id : '',
          badan_otonom: bot
      },

      this.file_logo = d.file_logo === null ? '' : d.file_logo
      this.file_bendera = d.file_bendera === null ? '' : d.file_bendera
      this.file_npwp = d.file_npwp === null ? '' : d.file_npwp
      this.file_sk_pengurus = d.file_sk_pengurus === null ? '' : d.file_sk_pengurus
      this.selectedPropinsi = d.provinsi_sekretariat

      if(d.infoPendirian != null){
        this.formPendiri = {
          namaNotaris: d.infoPendirian.namaNotaris,
          nomorAkta: d.infoPendirian.nomorAkta,
          tanggalAkta: d.infoPendirian.tanggalAkta,
          tanggalPendirian: d.infoPendirian.tanggalPendirian,
          nomorSuratPendaftaran: d.infoPendirian.nomorSuratPendaftaran,
          tanggalSuratPendaftaran: d.infoPendirian.tanggalSuratPendaftaran,
          provinsi: d.infoPendirian.wilayahProvinsi ? d.infoPendirian.wilayahProvinsi.kode : '',
          kabupatenKota: d.infoPendirian.wilayahKabupatenKota ? d.infoPendirian.wilayahKabupatenKota.kode : '',
          file_akta: d.infoPendirian.file_akta
        }
      }
      if(d.strukturOrmas !== null){
        var pengInti = []
        
        if(d.strukturOrmas.pengurusInti.length === 0) {
          for(var dd = 0; dd < 3; dd++) {
            pengInti.push({
              nama_pengurus: '',
              periode_mulai: '',
              periode_selesai: '',
              m_jabatan_id: null,
              identitas: '',
              
              tanggal_lahir: '',
              no_hp: '',
              foto: ''
            })
          }
        } else {
          for(var pi = 0; pi < d.strukturOrmas.pengurusInti.length; pi++){
            let pinti = d.strukturOrmas.pengurusInti[pi]
            pengInti.push({
                nama_pengurus: pinti.nama_pengurus,
                periode_mulai: pinti.periode_mulai,
                periode_selesai: pinti.periode_selesai,
                m_jabatan_id: pinti.m_jabatan_id,
                identitas: pinti.identitas,
                
                tanggal_lahir: pinti.tanggal_lahir,
                no_hp: pinti.no_hp,
                foto: pinti.foto
              })
          }
        }

        var pendiriList = []
        var pembinaList = []
        var penasihatList = []
        var pelindungList = []
        for(var ab = 0; ab < d.strukturOrmas.listPengurus.length; ab++){
          var SO = d.strukturOrmas.listPengurus[ab]
          if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'pendiri') {
            pendiriList.push({
              nama_pengurus: SO.nama_pengurus,
              identitas: SO.identitas
            })
          }
          if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'pembina') {
            pembinaList.push({
              nama_pengurus: SO.nama_pengurus,
              identitas: SO.identitas
            })
          }
          if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'penasehat') {
            penasihatList.push({
              nama_pengurus: SO.nama_pengurus,
              identitas: SO.identitas
            })
          }
          if(SO.masterKategoriPengurus.kode_kategori_pengurus == 'pelindung') {
            pelindungList.push({
              nama_pengurus: SO.nama_pengurus,
              identitas: SO.identitas
            })
          }
        }

        if(pendiriList.length == 0) pendiriList.push({nama_pengurus: '',identitas: ''})
        if(pembinaList.length == 0) pembinaList.push({nama_pengurus: '',identitas: ''})
        if(penasihatList.length == 0) penasihatList.push({nama_pengurus: '',identitas: ''})
        if(pelindungList.length == 0) pelindungList.push({nama_pengurus: '',identitas: ''})
        this.formStruktur = {
          pengurusInti:pengInti,
          pengurus: {
            pendiri: pendiriList,
            pembina: pembinaList,
            penasehat: penasihatList,
            pelindung: pelindungList
          }
        }
      }

      if(d.rekeningOrmas !== null) {
        var rek = []
        for(var rr = 0; rr < d.rekeningOrmas.length; rr++){
          var reken = d.rekeningOrmas[rr]
          rek.push({
            no_rekening: reken.no_rekening,
            nama_bank: reken.nama_bank,
            cabang_bank: '',
            nama_pemilik_rekening: reken.nama_pemilik_rekening,
            id_jenis_rekening: reken.id_jenis_rekening
          })
        }
        if(rek.length == 0) {
          rek = [{
            no_rekening: '',
            nama_bank: '',
            cabang_bank: '',
            nama_pemilik_rekening: '',
            id_jenis_rekening: null
          }]
        }
        this.formRekening = rek
      }
      } catch(e){
        Swal.fire({
          title: "Data Gagal Ditampilkan",
          text: "",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Lanjut",
        })
      }
      
      this.$bvModal.show('modal-form-ormas')
        
      })
    },
    toDetail(id){
      this.tabDetail = 0
      this.$store
      .dispatch("getDetailPenambahanOrmas", id)
      .then((res) => {
        this.idPenambahan = res.data.data.id
        this.getListCabang(res.data.data.id)
        let d = res.data.data
        this.info_organization = {
        "id": null,
        file_bendera: d.file_bendera,
        file_logo: d.file_logo,
        file_npwp: d.file_npwp,
        file_sk_pengurus: d.file_sk_pengurus,
        "nama_ormas": d.nama_ormas,
        "nama_singkat": d.nama_singkat,
        "npwp": d.npwp,
        "alamat_sekretariat": d.alamat_sekretariat,
        "no_skt": d.no_skt,
        "tanggal_skt": "",
        "tanggal_berdiri": "",
        "email": d.email,
        "no_telp": d.no_telp,
        "no_fax": d.no_fax,
        "m_lokasi_sekretariat_id": d.m_lokasi_sekretariat_id,
        "provinsi_sekretariat": d.infoPendirian && d.infoPendirian.wilayahProvinsi ? d.infoPendirian.wilayahProvinsi.nama : '',
        "kabupaten_kota_sekretariat": d.infoPendirian && d.infoPendirian.wilayahKabupatenKota ? d.infoPendirian.wilayahKabupatenKota.nama : '',
        "m_ciri_khusus_id": d.masterCiriKhusus ? d.masterCiriKhusus.id : d.m_ciri_khusus_id,
        berhimpunOtonom: d.badanOtonom,
        wadahBerhimpun: d.wadahBerhimpun,
        "bidangKegiatans": d.bidangKegiatan,
        "tujuanOrmas": d.tujuanOrmas,
        "masterProvinsi": d.masterProvinsi,
        "masterKabupatenKota": d.masterKabupatenKota,
        "masterCiriKhusus": d.masterCiriKhusus ? d.masterCiriKhusus.id : {nama_ciri_khusus: d.m_ciri_khusus_id}
      },
      this.founder_organization = {
        "nomorAkta": d.infoPendirian ? d.infoPendirian.nomorAkta : '',
        namaNotaris: d.infoPendirian ? d.infoPendirian.namaNotaris : '',
        "tanggalAkta": d.infoPendirian ? d.infoPendirian.tanggalAkta : '',
        // disini error nama
        "tempatPendirian": d.infoPendirian ? d.infoPendirian.wilayahProvinsi ? d.infoPendirian.wilayahProvinsi.nama : '' + ' ' + d.infoPendirian.wilayahKabupatenKota ? d.infoPendirian.wilayahKabupatenKota.nama : '' : '',
        "tanggalPendirian": d.infoPendirian ? d.infoPendirian.tanggalPendirian : '',
        file_akta: d.infoPendirian ? d.infoPendirian.file_akta : '',
        "nomorSuratPendaftaran": d.infoPendirian ? d.infoPendirian.nomorSuratPendaftaran : '',
        "tanggalSuratPendaftaran": d.infoPendirian ? d.infoPendirian.tanggalAkta : ''
      }
      this.structure_organization = {
        "pengurusInti": d.strukturOrmas ? d.strukturOrmas.pengurusInti : '',
        "listPengurus": d.strukturOrmas ? d.strukturOrmas.listPengurus : ''
      },
      this.document_organization = d.dokumenOrmas ? d.dokumenOrmas : ''
      this.bank_account_organization = d.rekeningOrmas ? d.rekeningOrmas : ''
      this.$bvModal.show('modal-profil')
        // this.$nextTick(() => {
        
        // })
      })
    },
    validateStateN() {
      const { $dirty, $error } = this.$v.selectedPropinsi;
      return $dirty ? !$error : null;
    },
    validateStateKot() {
      const { $dirty, $error } = this.$v.formProfil.kabupaten_kota_sekretariat;
      return $dirty ? !$error : null;
    },
    validateStateKot2() {
      const { $dirty, $error } = this.$v.formPendiri.kabupatenKota;
      return $dirty ? !$error : null;
    },
    validateStateP() {
      const { $dirty, $error } = this.$v.selectedPendiriPropinsi;
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.formPendiri[name];
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formProfil[name];
      return $dirty ? !$error : null;
    },
    validateStateFPBid(index){
      const { $dirty, $error } = this.$v.formProfil.bidang_kegiatan.$each.$iter[index];
      return $dirty ? !$error : null;
    },
    validateStateFPBid2(index){
      const { $dirty, $error } = this.$v.formProfil.badan_otonom.$each.$iter[index];
      return $dirty ? !$error : null;
    },
    validateStateSOid(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurusInti.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateSOP(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurus.pendiri.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateSOPM(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurus.pembina.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateSOPN(index, name){
      const { $dirty, $error } = this.$v.formStruktur.pengurus.penasehat.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateRek(index, name){
      const { $dirty, $error } = this.$v.formRekening.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateC(name) {
      const { $dirty, $error } = this.$v.formCabang[name];
      return $dirty ? !$error : null;
    },
    validateStateCS(index, name){
      const { $dirty, $error } = this.$v.formCabang.strukturOrganisasi.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateRekC(index, name){
      const { $dirty, $error } = this.$v.formRekeningC.$each.$iter[index][name];
      return $dirty ? !$error : null;
    },
    validateStateCP2(){
      const { $dirty, $error } = this.$v.selectedCabangPropinsi2;
      return $dirty ? !$error : null;
    },
    validateStateCP(){
      const { $dirty, $error } = this.$v.selectedCabangPropinsi;
      return $dirty ? !$error : null;
    },
    addRek(){
      this.$v.formRekening.$touch();
      if (!this.$v.formRekening.$anyError) {
        // this.loading = false
        // return;\
        this.formRekening.push({no_rekening: '',nama_bank: '',cabang_bank: '',nama_pemilik_rekening: '',id_jenis_rekening: null})
      }
      
    },
    addRekC(){
      this.$v.formRekeningC.$touch();
      if (!this.$v.formRekeningC.$anyError) {
        // this.loading = false
        // return;\
        this.formRekeningC.push({no_rekening: '',nama_bank: '',cabang_bank: '',nama_pemilik_rekening: '',id_jenis_rekening: null})
      }
      
    },
    setEnd(val, ind){
      if(this.yearMBend[ind].length > 0) this.yearMBend[ind].splice(-5)
      var nn = val - 2000
      for(var i = 0; i < 5; i++){
        var aa = nn + i
        if (aa < 10) this.yearMBend[ind].push('200' + aa)
        else this.yearMBend[ind].push('20' + aa)
      }
    },
    newTab(url){
      window.open(url);
    },
    cekProfile(){
      this.$v.formProfil.nama_ormas.$touch();
      this.$v.selectedPropinsi.$touch()
      this.$v.formProfil.kabupaten_kota_sekretariat.$touch()
      if (this.$v.formProfil.nama_ormas.$anyError || this.$v.formProfil.$anyError || this.$v.selectedPropinsi.$anyError){
        return;
      }
      var valbid = []
      for(var a = 0; a < this.formProfil.bidang_kegiatan.length; a++){
        valbid.push(this.formProfil.bidang_kegiatan[a].id)
      }
      var valbdon = []
      for(var b = 0; b < this.formProfil.badan_otonom.length; b++){
        valbdon.push(this.formProfil.badan_otonom[b].id)
      }
      var dataform = {
                    "dataOrmas": {
                      "file_logo": this.file_logo,
                      "file_bendera": this.file_bendera,
                      "nama_ormas": this.formProfil.nama_ormas,
                      "nama_singkat": this.formProfil.singkatan,
                      "m_lokasi_sekretariat_id": this.formProfil.status_sekretariat,
                      "alamat_sekretariat": this.formProfil.alamat_sekretariat,
                      "provinsi_sekretariat": this.selectedPropinsi ,
                      "kabupaten_kota_sekretariat": this.formProfil.kabupaten_kota_sekretariat,
                      "no_telp": this.formProfil.no_telp,
                      "no_fax": this.formProfil.no_fax,
                      "email": this.formProfil.email,
                      "tujuanOrmas": this.formProfil.tujuan_ormas,
                      "npwp": this.formProfil.npwp,
                      "file_npwp": this.file_npwp,
                      "m_ciri_khusus_id": this.formProfil.m_ciri_khusus_id,
                      // "file_skt": this.file_skt,
                      "file_sk_pengurus": this.file_sk_pengurus,
                    },
                    "bidangKegiatan": valbid,
                    "wadahBerhimpun": this.formProfil.wadahBerhimpun,
                    "badanOtonom": valbdon
                  }
      if(dataform.dataOrmas.file_logo == '') delete dataform.dataOrmas.file_logo
      if(dataform.dataOrmas.file_bendera == '') delete dataform.dataOrmas.file_bendera
      if(dataform.dataOrmas.nama_singkat == '' || dataform.dataOrmas.nama_singkat == null) delete dataform.dataOrmas.nama_singkat
      if(dataform.dataOrmas.m_lokasi_sekretariat_id == null) delete dataform.dataOrmas.m_lokasi_sekretariat_id
      if(dataform.dataOrmas.alamat_sekretariat == '' || dataform.dataOrmas.alamat_sekretariat == null) delete dataform.dataOrmas.alamat_sekretariat
      // if(dataform.dataOrmas.provinsi_sekretariat == '') delete dataform.dataOrmas.provinsi_sekretariat
      // if(dataform.dataOrmas.kabupaten_kota_sekretariat == '') delete dataform.dataOrmas.kabupaten_kota_sekretariat
      if(dataform.dataOrmas.no_telp == '' || dataform.dataOrmas.no_telp == null) delete dataform.dataOrmas.no_telp
      if(dataform.dataOrmas.no_fax == '' || dataform.dataOrmas.no_fax == null) delete dataform.dataOrmas.no_fax
      if(dataform.dataOrmas.email == '' || dataform.dataOrmas.email == null) delete dataform.dataOrmas.email
      if(dataform.dataOrmas.tujuanOrmas == '' || dataform.dataOrmas.tujuanOrmas == null) delete dataform.dataOrmas.tujuanOrmas
      if(dataform.dataOrmas.npwp == '' || dataform.dataOrmas.npwp == null) delete dataform.dataOrmas.npwp
      if(dataform.dataOrmas.file_npwp == '') delete dataform.dataOrmas.file_npwp
      if(dataform.dataOrmas.m_ciri_khusus_id == '' || dataform.dataOrmas.m_ciri_khusus_id == null) delete dataform.dataOrmas.m_ciri_khusus_id
      if(dataform.bidangKegiatan[0] == '') delete dataform.bidangKegiatan
      if(dataform.wadahBerhimpun == '') delete dataform.wadahBerhimpun
      if(dataform.badanOtonom[0] == '') delete dataform.badanOtonom
      // if(dataform.dataOrmas.file_skt == '') delete dataform.dataOrmas.file_skt
      var path = this.editPenambahanMode ? 'patchPenambahanOrmasProfil' : 'submitPenambahanOrmasProfil'
      this.$store
        .dispatch(path, dataform)
        .then((res) => {
          var idnya = res.data.data.id
          this.idPenambahan = idnya
          localStorage.setItem('idPenambahanOrmas', idnya)
          this.activeTab = 1
          window.scrollTo(0,0);
          Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() =>{
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
    },
    cekInfoPendirian(){
      this.formPendiri.provinsi = this.selectedPendiriPropinsi
      this.formPendiri.tanggalAkta = this.formPendiri.tanggalAkta != null ? this.formPendiri.tanggalAkta.substring(0, 10) : null
      this.formPendiri.tanggalPendirian = this.formPendiri.tanggalPendirian != null ? this.formPendiri.tanggalPendirian.substring(0, 10) : null
      this.formPendiri.tanggalSuratPendaftaran = this.formPendiri.tanggalSuratPendaftaran != null ? this.formPendiri.tanggalSuratPendaftaran.substring(0, 10) : null

      var dataform = {
        infoPendirian: this.formPendiri,
        file_akta: this.formPendiri.file_akta != null && this.formPendiri.file_akta != '' ? this.formPendiri.file_akta : ''
      }
      if(dataform.infoPendirian.namaNotaris == '') delete dataform.infoPendirian.namaNotaris
      if(dataform.infoPendirian.nomorAkta == '') delete dataform.infoPendirian.nomorAkta
      if(dataform.infoPendirian.file_akta == '') delete dataform.infoPendirian.file_akta
      if(dataform.infoPendirian.tanggalAkta == '' || dataform.infoPendirian.tanggalAkta == null) delete dataform.infoPendirian.tanggalAkta
      if(dataform.infoPendirian.provinsi == '') delete dataform.infoPendirian.provinsi
      if(dataform.infoPendirian.kabupatenKota == '') delete dataform.infoPendirian.kabupatenKota
      if(dataform.infoPendirian.tanggalPendirian == '' || dataform.infoPendirian.tanggalPendirian == null) delete dataform.infoPendirian.tanggalPendirian
      if(dataform.infoPendirian.nomorSuratPendaftaran == '' || dataform.infoPendirian.nomorSuratPendaftaran == null) delete dataform.infoPendirian.nomorSuratPendaftaran
      if(dataform.infoPendirian.tanggalSuratPendaftaran == '' || dataform.infoPendirian.tanggalSuratPendaftaran == null) delete dataform.infoPendirian.tanggalSuratPendaftaran
      if(dataform.file_akta == '') delete dataform.file_akta
      if(JSON.stringify(dataform.infoPendirian) == '{}') delete dataform.infoPendirian
      this.$store
        .dispatch("submitPenambahanOrmasPendirian", dataform)
        .then((/*res*/) => {
          this.activeTab = 2
          window.scrollTo(0,0);
          Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() =>{
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
      
    },
    cekStruktur(formToSubmit){
      // let formToSubmit = this.formStruktur
      if(formToSubmit.pengurusInti){
        for(var i = 0; i < formToSubmit.pengurusInti.length; i++){
          formToSubmit.pengurusInti[i].periode_mulai = formToSubmit.pengurusInti[i].periode_mulai != '' ? formToSubmit.pengurusInti[i].periode_mulai.substring(0, 10) : ''
          formToSubmit.pengurusInti[i].periode_selesai = formToSubmit.pengurusInti[i].periode_selesai != '' ? formToSubmit.pengurusInti[i].periode_selesai.substring(0, 10) : ''
          if(formToSubmit.pengurusInti[i].foto == '') delete formToSubmit.pengurusInti[i].foto
          if(formToSubmit.pengurusInti[i].nama_pengurus == '') delete formToSubmit.pengurusInti[i].nama_pengurus
          if(formToSubmit.pengurusInti[i].periode_mulai == '') delete formToSubmit.pengurusInti[i].periode_mulai
          if(formToSubmit.pengurusInti[i].periode_selesai == '') delete formToSubmit.pengurusInti[i].periode_selesai
          if(formToSubmit.pengurusInti[i].m_jabatan_id == null) delete formToSubmit.pengurusInti[i].m_jabatan_id
          if(formToSubmit.pengurusInti[i].identitas == '') delete formToSubmit.pengurusInti[i].identitas
          if(formToSubmit.pengurusInti[i].tanggal_lahir == '') delete formToSubmit.pengurusInti[i].tanggal_lahir
          if(formToSubmit.pengurusInti[i].no_hp == '') delete formToSubmit.pengurusInti[i].no_hp
          if(formToSubmit.pengurusInti[i].file_identitas == '') delete formToSubmit.pengurusInti[i].file_identitas
          if(JSON.stringify(formToSubmit.pengurusInti[i]) == '{}' || formToSubmit.pengurusInti[i] == null) delete formToSubmit.pengurusInti[i]
        }
        if(JSON.stringify(formToSubmit.pengurusInti) === '[null,null,null]') formToSubmit.pengurusInti = []
      } else {
        formToSubmit.pengurusInti = []
      }
      for(var a = 0; a < formToSubmit.pengurus.pendiri.length; a++){
        
        if(formToSubmit.pengurus.pendiri[a].identitas == '') delete formToSubmit.pengurus.pendiri[a].identitas
        if(formToSubmit.pengurus.pendiri[a].nama_pengurus == '') delete formToSubmit.pengurus.pendiri[a].nama_pengurus
        // if(JSON.stringify(formToSubmit.pengurus.pendiri) == '[{}]') delete formToSubmit.pengurus.pendiri
      }

      for(var b = 0; b < formToSubmit.pengurus.penasehat.length; b++){
        if(formToSubmit.pengurus.penasehat[b].identitas == '') delete formToSubmit.pengurus.penasehat[b].identitas
        if(formToSubmit.pengurus.penasehat[b].nama_pengurus == '') delete formToSubmit.pengurus.penasehat[b].nama_pengurus
        // if(JSON.stringify(formToSubmit.pengurus.penasehat) == '[{}]') delete formToSubmit.pengurus.penasehat
      }

      for(var c = 0; c < formToSubmit.pengurus.pembina.length; c++){
        
        if(formToSubmit.pengurus.pembina[c].identitas == '') delete formToSubmit.pengurus.pembina[c].identitas
        if(formToSubmit.pengurus.pembina[c].nama_pengurus == '') delete formToSubmit.pengurus.pembina[c].nama_pengurus
        // if(JSON.stringify(formToSubmit.pengurus.pembina) == '[{}]') delete formToSubmit.pengurus.pembina
      }
      for(var d = 0; d < formToSubmit.pengurus.pelindung.length; d++){
        if(formToSubmit.pengurus.pelindung[d].identitas == '') delete formToSubmit.pengurus.pelindung[d].identitas
        if(formToSubmit.pengurus.pelindung[d].nama_pengurus == '') delete formToSubmit.pengurus.pelindung[d].nama_pengurus
        // if(JSON.stringify(formToSubmit.pengurus.pelindung) == '[{}]') delete formToSubmit.pengurus.pelindung
      }
      
      if(!formToSubmit.pengurus.pendiri && !formToSubmit.pengurus.penasehat && !formToSubmit.pengurus.pembina && !formToSubmit.pengurus.pelindung) formToSubmit.pengurus = {}
      
      if(formToSubmit.file_sk_pengurus == '') delete formToSubmit.file_sk_pengurus
      
      if(JSON.stringify(formToSubmit.pengurus.pendiri) == '[{}]') delete formToSubmit.pengurus.pendiri
      if(JSON.stringify(formToSubmit.pengurus.pembina) == '[{}]') delete formToSubmit.pengurus.pembina
      if(JSON.stringify(formToSubmit.pengurus.penasehat) == '[{}]') delete formToSubmit.pengurus.penasehat
      if(JSON.stringify(formToSubmit.pengurus.pelindung) == '[{}]') delete formToSubmit.pengurus.pelindung
      if(JSON.stringify(formToSubmit.file_sk_pengurus) == '{}') delete formToSubmit.file_sk_pengurus

      // var dataform = formToSubmit
      this.$store
        .dispatch("submitPenambahanOrmasStruktur", formToSubmit)
        .then(() => {
          this.$nextTick(() => {
            this.activeTab = 4
            window.scrollTo(0,0);
          })
         Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() =>{
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })

    },
    cekRekening(){
      // let dform = this.formRekening
      this.$v.formRekening.$touch();
      var reken = []
      for(var i = 0; i < this.formRekening.length; i++){
        var d = this.formRekening[i]
        if(d.id_jenis_rekening !== null || d.nama_bank !== '' || d.nama_pemilik_rekening !== '' || d.no_rekening !== '') {
          reken.push(d)
        }
      }
      
      let dataform = {
          "rekeningOrmas": reken}
      this.$store
      .dispatch("submitPenambahanOrmasRekening", dataform)
      .then((/*res*/) => {
        this.activeTab = 5
        window.scrollTo(0,0);
       Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() =>{
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
      
    },
    cekDokumen(){
      this.activeTab = 3
      window.scrollTo(0,0);
    },
    
    deleteCabang(id){
      const context = this
      Swal.fire({
            title: "Anda yakin ingin menghapus cabang?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Hapus",
          }).then((result) => {
            if (result.isConfirmed) {
              context.$store.dispatch("deleteCabangKelengkapan", id).then((/*res*/) => {
                Swal.fire({
                  title: "Cabang berhasil dihapus",
                  text: "",
                  icon: "success",
                  showCancelButton: false,
                  confirmButtonColor: "#3085d6",
                  confirmButtonText: "Lanjut",
                }).then(() => {
                  context.getListCabang()
                })
              })
            }
          });
    },
    saveCabang(){
      
      var rek = []
      for(var a=0 ; a < this.formRekeningC.length; a++){
        rek.push({
          no_rekening: this.formRekeningC[a].no_rekening,
          nama_bank: this.formRekeningC[a].nama_bank,
          nama_pemilik_rekening: this.formRekeningC[a].nama_pemilik_rekening,
          id_jenis_rekening: parseInt(this.formRekeningC[a].id_jenis_rekening),
          cabang_bank: ''
        })
      }
      var dataform = {
        "profil": {
          // "nama_cabang": this.formCabang.nama_cabang,
          "no_telp": this.formCabang.no_telp,
          "no_fax": this.formCabang.no_fax,
          "alamat_sekretariat": this.formCabang.alamat_sekretariat,
          "npwp": this.formCabang.npwp,
          // "tanggal_berdiri": this.formCabang.tanggalPendirian,
          "provinsi_sekretariat": parseFloat(this.selectedCabangPropinsi),
          "kabupaten_kota_sekretariat": parseFloat(this.formCabang.kabupaten_kota_sekretariat),
          "m_lokasi_sekretariat_id": this.formCabang.m_lokasi_sekretariat_id
        },
        "infoPendirian": {
          "namaNotaris": this.formCabang.namaNotaris,
          "nomorAkta": this.formCabang.nomorAkta,
          "tanggalAkta": this.formCabang.tanggalAkta,
          "kabupatenKota": this.formCabang.kabupatenKota,
          "tanggalPendirian": this.formCabang.tanggalPendirian,
          "nomorSuratPendaftaran": this.formCabang.nomorSuratPendaftaran,
          "tanggalSuratPendaftaran": this.formCabang.tanggalAkta,
          "provinsi": this.selectedCabangPropinsi2
        },
        "file_sk_pengurus": this.file_sk_pengurus_cabang,
        "strukturOrganisasi": this.formCabang.strukturOrganisasi,
        "rekeningOrmas": rek
      }
      if(dataform.file_sk_pengurus == '') delete dataform.file_sk_pengurus
      if(dataform.profil.alamat_sekretariat == '') delete dataform.profil.alamat_sekretariat
      if(dataform.profil.m_lokasi_sekretariat_id == '') delete dataform.profil.m_lokasi_sekretariat_id
      if(dataform.profil.no_fax == '') delete dataform.profil.no_fax
      if(dataform.profil.no_telp == '') delete dataform.profil.no_telp
      if(dataform.profil.npwp == '') delete dataform.profil.npwp
      if(dataform.profil.provinsi_sekretariat == '' || !dataform.profil.provinsi_sekretariat) delete dataform.profil.provinsi_sekretariat
      if(dataform.profil.kabupaten_kota_sekretariat == '' || !dataform.profil.kabupaten_kota_sekretariat) delete dataform.profil.kabupaten_kota_sekretariat

      if(dataform.infoPendirian.kabupatenKota == '') delete dataform.infoPendirian.kabupatenKota
      if(dataform.infoPendirian.namaNotaris == '') delete dataform.infoPendirian.namaNotaris
      if(dataform.infoPendirian.nomorAkta == '') delete dataform.infoPendirian.nomorAkta
      if(dataform.infoPendirian.nomorSuratPendaftaran == '') delete dataform.infoPendirian.nomorSuratPendaftaran
      if(dataform.infoPendirian.provinsi == '') delete dataform.infoPendirian.provinsi
      if(dataform.infoPendirian.tanggalAkta == '') delete dataform.infoPendirian.tanggalAkta
      if(dataform.infoPendirian.tanggalPendirian == '') delete dataform.infoPendirian.tanggalPendirian
      if(dataform.infoPendirian.tanggalSuratPendaftaran == '') delete dataform.infoPendirian.tanggalSuratPendaftaran

      // if(dataform.rekeningOrmas[0].cabang_bank == '') delete dataform.rekeningOrmas.cabang_bank
      // if(dataform.rekeningOrmas[0].id_jenis_rekening == '' || !dataform.rekeningOrmas.id_jenis_rekening) delete dataform.rekeningOrmas.id_jenis_rekening
      // if(dataform.rekeningOrmas[0].nama_bank == '') delete dataform.rekeningOrmas.nama_bank
      // if(dataform.rekeningOrmas[0].nama_pemilik_rekening == '') delete dataform.rekeningOrmas.nama_pemilik_rekening
      // if(dataform.rekeningOrmas[0].no_rekening == '') delete dataform.rekeningOrmas.no_rekening
      // if()

      var submittingTo = ''

      if(this.createMode){
        submittingTo = 'submitPenambahanOrmasCabang'
        this.$store
        .dispatch(submittingTo, dataform)
        .then((/*res*/) => {
          this.getListCabang(localStorage.getItem('idPenambahanOrmas'))
          this.createCabang = false
          window.scrollTo(0,0);
          this.resetFormCabang()
         Swal.fire({
            title: "Data Berhasil Disimpan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() =>{
          Swal.fire({
            title: "Data Gagal Disimpan",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
      } else{ 
        submittingTo = 'submitPenambahanCabangPatch'
        this.$store
        .dispatch(submittingTo, [dataform, this.idCabang])
        .then((/*res*/) => {
          this.createCabang = false
          this.getListCabang(localStorage.getItem('idPenambahanOrmas'))
          window.scrollTo(0,0);
          this.resetFormCabang()
         Swal.fire({
            title: "Data Berhasil Diubah",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        }).catch(() =>{
          Swal.fire({
            title: "Data Gagal Diubah",
            text: "",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          })
        })
        
      }
      
    },
    resetForm(){
      localStorage.setItem('idPenambahanOrmas', null)
      this.listCabang = []
      this.listDokumen = []
      this.file_logo = ''
      this.file_bendera = ''
      this.file_skt = ''
      this.file_npwp = ''
      this.file_sk_pengurus = ''
      this.formProfil = {
          file_esign_skt: null,
          file_scan_notaris: null,
          file_npwp: null,
          file_scan_surat_permohonan: null,
          nama_ormas: '',
          singkatan: '',
          // no_skt: '',
          status_sekretariat: null,
          alamat_sekretariat: '',
          kabupaten_kota_sekretariat: '',
          provinsi_sekretariat: '',
          no_telp: '',
          no_fax: '',
          email: '',
          tujuan_ormas: '',
          npwp: '',
          m_ciri_khusus_id: '',
          bidang_kegiatan: [{id: ''}],
          wadahBerhimpun: '',
          badan_otonom: [{id: ''}]
      }
      this.formPendiri = {
        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: "",
        tanggalPendirian: "",
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: "",
        provinsi: '',
        kabupatenKota: '',
        file_akta: ''
      }
      this.formStruktur = {
        file_sk_pengurus: '',
        pengurusInti:[
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: '',
            foto: '',
            file_identitas: ''
          }
        ],
        pengurus: {
          pendiri: [
            {
              nama_pengurus: "",
              identitas: ""
            }
          ],
          pembina: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ],
          penasehat: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ],
          pelindung: [
            {
              nama_pengurus: '',
              identitas: ''
            }
          ]
        }
      }
      this.formRekening = [
        {
          no_rekening: '',
          nama_bank: '',
          cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening: null
        }
      ]
    },
    resetFormCabang(){
      this.formCabang = {
        nama_cabang: '',
        no_telp: '',
        singkatan:'',
        no_fax: '',
        alamat_sekretariat: '',
        npwp: '',
        tanggal_berdiri: '',
        kabupaten_kota_sekretariat: '',
        m_lokasi_sekretariat_id: '',

        namaNotaris: '',
        nomorAkta: '',
        tanggalAkta: '',
        kabupatenKota: '',
        tanggalPendirian: '',
        nomorSuratPendaftaran: '',
        tanggalSuratPendaftaran: '-',
        strukturOrganisasi: [
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: ''
          },
          {
            nama_pengurus: '',
            periode_mulai: '',
            periode_selesai: '',
            m_jabatan_id: null,
            identitas: '',
            
            tanggal_lahir: '',
            no_hp: ''
          }
        ],
      }
      this.formRekeningC = [
        {
          no_rekening: '',
          nama_bank: '',
          // cabang_bank: '',
          nama_pemilik_rekening: '',
          id_jenis_rekening:''  
        }
      ]

      this.selectedCabangPropinsi = ''
      this.selectedCabangPropinsi2 = ''
    },
    fillFormCabang(d, id){
      var peng = []
      for(var b=0 ; b < d.strukturOrganisasi.length; b++){
        var dd = d.strukturOrganisasi[b]
        peng.push(
          {
            nama_pengurus: dd.nama_pengurus,
            periode_mulai: dd.periode_mulai.substring(0, 10),
            periode_selesai: dd.periode_selesai.substring(0, 10),
            m_jabatan_id: dd.m_jabatan_id,
            identitas: dd.identitas,
            
            tanggal_lahir: dd.tanggal_lahir.substring(0, 10),
            no_hp: dd.no_hp,
            foto: dd.foto
          }
        )
      }

      this.idCabang = id
      this.formCabang = {
        nama_cabang: d.nama_cabang ? d.nama_cabang : '',
        no_telp: d.no_telp,
        singkatan:'',
        no_fax: d.no_fax,
        alamat_sekretariat: d.alamat_sekretariat,
        npwp: d.npwp,
        // tanggal_berdiri: d.tanggal_berdiri.substring(0, 10),
        kabupaten_kota_sekretariat: d.kabupaten_kota_sekretariat,
        m_lokasi_sekretariat_id: d.m_lokasi_sekretariat_id,

        namaNotaris: d.infoPendirian.namaNotaris,
        nomorAkta: d.infoPendirian.nomorAkta,
        tanggalAkta: d.infoPendirian.tanggalAkta.substring(0, 10),
        kabupatenKota: d.infoPendirian.kabupatenKota,
        tanggalPendirian: d.infoPendirian.tanggalPendirian.substring(0, 10),
        nomorSuratPendaftaran: d.infoPendirian.nomorSuratPendaftaran,
        tanggalSuratPendaftaran: d.infoPendirian.tanggalSuratPendaftaran.substring(0, 10),
        strukturOrganisasi: peng,
      }
      
      this.selectedCabangPropinsi = d.provinsi_sekretariat
      this.selectedCabangPropinsi2 = d.infoPendirian.provinsi
      this.file_sk_pengurus_cabang = d.file_sk_pengurus

      var rek = []
      for(var a=0 ; a < d.rekeningOrmas.length; a++){
        rek.push({
          no_rekening: d.rekeningOrmas[a].no_rekening,
          nama_bank: d.rekeningOrmas[a].nama_bank,
          nama_pemilik_rekening: d.rekeningOrmas[a].nama_pemilik_rekening,
          id_jenis_rekening: d.rekeningOrmas[a].id_jenis_rekening
        })
      }
      this.formRekeningC = rek

      
    },
    addCabang(){
      this.resetFormCabang()
      this.createMode = true
      this.createCabang = true
    },
    editCabang(id){
      this.$v.$reset()
      this.$store
        .dispatch('getDetailCabangPenambahan',id)
        .then((res) => {
          this.resetFormCabang()
          this.createMode = false
          this.createCabang = true
          // this.$bvModal.show('modal-form-ormas')
          this.fillFormCabang(res.data.data, id)
        })
    },
    getDetailCabang(id){
      this.$store
        .dispatch('getDetailCabangPenambahan',id)
        .then((res) => {
          // this.resetFormCabang()
          // this.createMode = false
          this.detailCabang = res.data.data
          this.$bvModal.show('detail-cabang')
          // this.resetFormCabang()
          // this.fillFormCabang(res.data.data, id)
        })
    },
    getListDokumen(){
      this.$store
        .dispatch('getDokumenList')
        .then((res) => {
          // this.resetFormCabang()
          // this.createMode = false
          this.listDokumen = res.data
          // this.resetFormCabang()
          // this.fillFormCabang(res.data.data, id)
        })
    },
    submitKelengkapan(){
          Swal.fire({
            title: "Data Berhasil Diajukan",
            text: "",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Lanjut",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$bvModal.hide('modal-form-ormas')
              this.getListOrmas()
              window.scrollTo(0,0);
            }
          });
    },
    cekCabang(){
      if(!this.vCabang) {
        this.vCabang = true
        // this.progress = this.progress + 25
      }
      this.$router.push('/dashboard')
    },
    back(){
      this.activeTab = this.activeTab - 1
      window.scrollTo(0,0);
    },
    getStatusSekretariat(){
      this.$store
        .dispatch("getStatusSekretariat")
        .then((res) => {
          this.$nextTick(() => {
            this.listSekretariat = res.data
          })
          })
    },
    getCiriKhusus(){
      this.$store
        .dispatch("getCiriKhusus")
        .then((res) => {
           this.$nextTick(() => {
            this.listCiriKhusus = res.data
          })
          })
    },

    getBadanOtonom(){
      this.$store
        .dispatch("getListOrmas")
        .then((res) => {
           this.$nextTick(() => {
            this.listBadanOtonom = res.data
          })
          })
    },
    getJabatan(){
      this.$store
      .dispatch("getJabatan")
      .then((res) => {
          this.$nextTick(() => {
          this.listJabatan = res.data
        })
        })
    },
    getBidangKegiatan(){
      this.$store
        .dispatch("getBidangKegiatan")
        .then((res) => {
           this.$nextTick(() => {
            this.listBidangKegiatan = res.data
          })
          })
    },
    getJenisRekening(){
      this.$store
        .dispatch("getJenisRekening")
        .then((res) => {
           this.$nextTick(() => {
            this.listRekening = res.data
          })
          })
    },
    getPropinsi(){
      this.$store
        .dispatch("getPropinsi")
        .then((res) => {
          
           this.$nextTick(() => {
            this.listPropinsi = res.data.data
          })
          })
    },
    getKotaProfil(id, kabkotaProfil){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            if(kabkotaProfil != null){
            this.formProfil.kabupaten_kota_sekretariat = kabkotaProfil
            }
            this.listKotaProfil = res.data.data
            
          })
          })
    },
    getKota(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota = res.data.data
          })
          })
    },
    getKotaProfil2(id, kabkotaProfil){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota2 = res.data.data
            this.formPendiri.kabupatenKota = kabkotaProfil
          })
          })
    },
    getKota3(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota3 = res.data.data
          })
          })
    },
    getKota4(id){
      this.$store
        .dispatch("getKabupatenKota", id)
        .then((res) => {
          
          this.$nextTick(() => {
            this.listKota4 = res.data.data
          })
          })
    },
    searchSubmit(){
      this.query.no = this.search.trim()

      if (this.query.no.length > 0 && this.query.no.length < 3) return

      this.getListOrmas()
    },
  },
  computed: {
    
  }
};
</script>

<style>
.nav.nav-pills .nav-link {
  color: #053A69;
  font-weight: bold;
}
.button-plus {
  background:#339933;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;

}
.button-minus {
  background:#FF4433;cursor:pointer; padding:5px; border-radius:50%;width:30px; height:30px;text-align:center; margin-left:5px;
}
.nav.nav-pills .nav-item {
    margin-right: 10px;
    margin-left: 10px;
}

.form-control.is-invalid{
  padding-right: 0px !important;
}
.form-control.is-valid{
  padding-right: 0px !important;
}
#modal-form-ormas .modal-dialog.modal-xl {
  max-width:100% !important;
  margin-left:1rem;
  margin-right:1rem;
}
.required:after {
    content: " * ";
    color: red;
}
</style>